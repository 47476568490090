<template>
	<div>
		<span v-if="isDutch">
			<p>
				Geachte lezer, Voor u liggen de algemene voorwaarden van The DJ Company. Omdat algemene voorwaarden door
				juristen worden geschreven is de tekst erg technisch. In feite zijn de voorwaarden niets anders dan een afspraak
				tussen u en ons, en komt deze simpelweg neer op het volgende:
			</p>
			<ol>
				<li>
					<strong>Wij doen ons uiterste best om onze afspraken na te komen. </strong>Wij moeten het hebben van
					mond-tot-mond reclame en zullen er alles aan doen om er een prachtig feest van te maken. Uw wensen zijn
					hierbij altijd leidend.
				</li>
				<li>
					<strong>Wij spreken met u 1 prijs af </strong>en u mag ervan uitgaan dat alle logische kosten hieronder
					vallen. Hierdoor komt u achteraf niet voor verrassingen te staan.
				</li>
				<li>
					<strong>U zorgt ervoor dat wij ons werk kunnen doen</strong> en dat onze apparatuur naar behoren beschermd is.
				</li>
				<li>
					U brengt ons zo goed mogelijk op de hoogte van alle aspecten van uw feest:
					<strong>we staan dan niet voor verrassingen</strong>.
				</li>
			</ol>
			<p>
				Samen nemen wij onze verantwoordelijkheden en zorgen we er voor dat we uw gelegenheid tot een succes maken. Wij
				hoeven ons dan ook nooit te beroepen op deze algemene voorwaarden. Dit blijkt ook wel uit het grote aantal
				tevreden klanten die ons hun feest toevertrouwden. Lees hier over
				<router-link class="ahref" :to="{name: 'Reviews'}">onze tevreden klanten</router-link>.
			</p>
			<p>
				<a
					href="https://thedjcompany.nl/download/nl-nl/20240101/algemene-voorwaarden.pdf"
					rel="noreferrer noopener"
					target="_blank"
					><font-awesome-icon :icon="['far', 'file-pdf']" class="mr-2" />Download onze algemene voorwaarden in
					PDF-formaat</a
				>
			</p>
			<h4>Algemene voorwaarden The DJ Company</h4>
			<h5>Artikel 1. Definities</h5>
			<p>
				In deze algemene voorwaarden en alle Overeenkomsten waarop deze algemene voorwaarden van toepassing zijn wordt
				verstaan:
			</p>
			<ol>
				<li>
					Onder “<strong>The DJ Company</strong>” : The DJ Company BV, gevestigd te Groningen en zoals ingeschreven bij
					de Kamer van Koophandel te Groningen onder nummer 73421413.
				</li>
				<li>
					Onder “<strong>Opdrachtgever</strong>” : de (rechts)persoon die Opdracht verleent aan The DJ Company tot het
					engageren van artiesten en/of musici en/of het verhuren van apparatuur en/of het organiseren van een evenement
					of festiviteit.
				</li>
				<li>
					Onder “<strong>Artiest</strong>” : de (beroeps)beoefenaar alleen of in groepsverband op het gebied van kunsten
					en amusement, die zich jegens The DJ Company heeft verbonden een bepaalde artistieke prestatie te verrichten.
					Onder (beroeps)beoefenaar wordt verstaan een diskjockey en/of artiest en/of musicus en/of presentator, die
					tegen betaling deelneemt aan voorstellingen, concerten en/of uitvoeringen en/of degene die tegen betaling
					directe technische of artistieke bijstand verleent ten behoeve van de onder Artikel 1. onder 2. bedoelde
					evenementen of festiviteiten.
				</li>
				<li>
					Onder “<strong>Opdracht</strong>” : de door de Opdrachtgever aan The DJ Company verstrekte Opdracht welke, in
					de ruimste zin van het woord, betrekking heeft op het verzorgen van artiesten en/of het verhuren en/of het in
					bruikleen geven van Zaken en / of het leveren van aanvullende en/of andere diensten en/of goederen als
					omschreven in de Overeenkomst.
				</li>
				<li>
					Onder “<strong>Overeenkomst</strong>” : de tussen The DJ Company en Opdrachtgever tot stand gekomen
					Overeenkomst tot Opdracht.
				</li>
				<li>
					Onder “<strong>Partijen</strong>” : alle betrokken partijen, te weten Artiest, The DJ Company en
					Opdrachtgever.
				</li>
				<li>
					Onder “<strong>Meerwerk</strong>”, alle wijzigingen/aanvullingen in, op of voortvloeiende uit de met de
					Opdrachtgever gesloten Overeenkomst, waardoor The DJ Company en/of de door haar ingeschakelde Toeleveranciers
					en/of Artiest meer dan wel gewijzigde werkzaamheden moeten verrichten en/of hogere (on)kosten moeten maken.
				</li>
				<li>
					Onder “<strong>Zaken</strong>”, de door The DJ Company voor het uitvoeren van de Opdracht gebruikte Zaken c.q.
					de ten behoeve van de uitvoering van de Opdracht aan de Opdrachtgever verhuurde of anderszins in gebruik
					gegeven Zaken, zoals geluids-, beeld- en lichtapparatuur, bekabeling, verpakkingsmaterialen, decorstukken,
					podiumdelen e.d.
				</li>
			</ol>
			<h5>Artikel 2. Toepasselijkheid</h5>
			<ol>
				<li>Deze algemene voorwaarden maken deel uit van alle offertes en Overeenkomsten met The DJ Company.</li>
				<li>
					Afwijkende bedingen en eventuele algemene voorwaarden van Opdrachtgever gelden slechts indien en voor zover
					deze uitdrukkelijk door The DJ Company schriftelijk zijn aanvaard.
				</li>
				<li>
					Bij sommige Opdrachten zijn aanvullende voorwaarden van toepassing. Deze zullen in dat geval bij de
					Overeenkomst verstrekt worden.
				</li>
				<li>
					Deze voorwaarden worden door The DJ Company bij de verstrekking van een offerte en bij de bevestiging van een
					Opdracht digitaal per e-mail verzonden aan Opdrachtgever in de vorm van een hyperlink naar een digitale versie
					van deze voorwaarden in de offerte dan wel de bevestiging van een Opdracht. Deze voorwaarden zijn tevens via
					internet te raadplegen op www.thedjcompany.nl.
				</li>
				<li>
					Indien en voor zover bij Overeenkomst is afgeweken van deze algemene voorwaarden, prevaleert het bepaalde in
					de Overeenkomst.
				</li>
			</ol>
			<h5>Artikel 3. Totstandkoming Overeenkomsten en offertes</h5>
			<ol>
				<li>
					Alle offertes van The DJ Company, zowel schriftelijk als mondeling, zijn steeds vrijblijvend en hebben een
					geldigheid die staat vermeld op de offerte
				</li>
				<li>
					Door ondertekening van de offerte gaat Opdrachtgever een Overeenkomst aan met The DJ Company inzake de
					Opdracht genoemd in de offerte. Opdrachtgever en The DJ Company hebben in deze Overeenkomst in ieder geval
					overeenstemming bereikt over: optredende Artiest(en), datum en tijdstippen Opdracht, vergoeding, locatie en
					overige dienstverlening.
				</li>
				<li>
					The DJ Company en Opdrachtgever zijn gebonden nadat Opdrachtgever de offerte inzake de Opdracht voorziet van
					handtekening en als zodanig aan The DJ Company retourneert, per e-mail of fax. Of na het online bevestigen per
					online link.
				</li>
				<li>
					Eventueel na ondertekening van de offerte te maken aanvullende of gewijzigde afspraken inzake de Opdracht zijn
					slechts van kracht indien deze door The DJ Company en Opdrachtgever schriftelijk zijn overeengekomen.
				</li>
				<li>
					Opdrachtgever heeft het recht om tot&nbsp;14&nbsp;dagen tot de uitvoerdatum kosteloos de Overeenkomst
					schriftelijk te annuleren, tenzij anders vermeld op de Overeenkomst.&nbsp;
				</li>
				<li>
					Bij een eventuele annulering na de in punt 5 genoemde datum&nbsp;is de Overeenkomst definitief tot stand
					gekomen en zijn de in deze algemene voorwaarden genoemde annuleringsbepalingen van kracht.
				</li>
				<li>
					Opdrachtgever en The DJ Company erkennen elektronische communicatie en zullen de geldigheid en het juridisch
					effect daarvan niet ontzeggen vanwege het enkele feit dat de communicatie elektronisch is.
				</li>
				<li>
					Meerwerk wordt afzonderlijk in rekening gebracht. Meerwerk wordt berekend op de grondslag van de bij het
					aangaan van de Overeenkomst gestelde condities. Minderwerk wordt slechts verrekend indien Partijen dit van
					tevoren schriftelijk zijn overeengekomen.
				</li>
				<li>
					Beeldmateriaal (mondeling, telefonisch, via e-mail verstrekt of op de website vermeld) van The DJ Company met
					betrekking tot alle offertes en de belangrijkste kenmerken van de Opdracht worden zo nauwkeurig mogelijk
					(weer)gegeven of gedaan. The DJ Company garandeert echter niet dat alle offertes en producten volledig met het
					gegeven beeldmateriaal et cetera in overeenstemming zijn. Afwijkingen kunnen in beginsel geen aanleiding zijn
					tot schadevergoeding en/of ontbinding.
				</li>
			</ol>
			<h5>Artikel 4. Uitvoering Overeenkomst</h5>
			<ol>
				<li>The DJ Company zal elke Overeenkomst naar beste kunnen uitvoeren.</li>
				<li>
					The DJ Company is gerechtigd bij en voor de uitvoering van een Overeenkomst derden (externe leveranciers) in
					te schakelen. Hoewel deze derden zorgvuldig geselecteerd en gecontracteerd worden kan The DJ Company niet
					aansprakelijk gesteld worden voor het handelen en nalaten van deze derden. The DJ Company verplicht zich
					echter wel een probleem dat ontstaan is door handelen of nalaten van door The DJ Company ingeschakelde derden
					naar beste kunnen op te lossen. Hiermee wordt o.a. het regelen van een gelijkwaardige vervanging van Artiest
					bij ziekte van de in de Overeenkomst beschreven Opdracht bedoeld.
				</li>
				<li>
					Indien op verzoek van Opdrachtgever wordt afgeweken van hetgeen oorspronkelijk werd overeengekomen zullen de
					meerkosten daarvan aan Opdrachtgever worden doorberekend. Hiermee wordt o.a. verlenging van de Opdracht
					bedoeld.
				</li>
				<li>
					Geringe afwijkingen in de uitvoering, ook in esthetische zin, geeft Opdrachtgever geen recht op korting op het
					de in Overeenkomst afgesproken vergoeding.
				</li>
			</ol>
			<h5>Artikel 5. Verantwoordelijkheden Opdrachtgever</h5>
			<ol>
				<li>
					Artiest is verplicht tijdig aanwezig te zijn, met alle Zaken die voor de Opdracht nodig zijn, tenzij
					schriftelijk van te voren is overeengekomen dat bepaalde voor de Opdracht benodigde Zaken door The DJ Company
					en/of de Opdrachtgever zullen worden verstrekt.
				</li>
				<li>
					Opdrachtgever verklaart, reeds door het aangaan van de Overeenkomst met The DJ Company, volledig bekend te
					zijn met de prestaties van de Artiest en, alsmede met de soort en/of de aard van de concreet overeengekomen
					Opdracht.
				</li>
				<li>
					Opdrachtgever dient zelf zorg te dragen voor de toestemming van derden of vergunningen die benodigd zijn voor
					uitvoering van de met The DJ Company in de Overeenkomst omschreven Opdracht. Opdrachtgever dient te voldoen
					aan alle (wettelijke) eisen en voorschriften.
				</li>
				<li>
					Opdrachtgever is zelf verantwoordelijk voor het handelen en nalaten van de bezoekers van een krachtens een
					Overeenkomst door The DJ Company uitgevoerde Opdracht.
				</li>
				<li>
					Opdrachtgever zal The DJ Company op de hoogte stellen van eventuele omleidingen, wegopbrekingen en/of
					-afsluitingen die de directe toegang tot de locatie van de Opdracht kunnen belemmeren. Vertragingen of niet
					optreden, veroorzaakt doordat aan deze eisen niet of onvoldoende wordt voldaan, kan nimmer leiden tot
					ontbinding van de Overeenkomst door de Opdrachtgever dan wel verhaal van enige schade op The DJ Company.
				</li>
				<li>
					Indien Artiest op weg naar de Opdracht oponthoud heeft of verwacht te krijgen, zal Opdrachtgever direct
					gewaarschuwd worden. Opdrachtgever zal voor dit doel een bereikbaar telefoonnummer ter beschikking stellen.
					Artiest zal, voor zover redelijkerwijs mogelijk, onmiddellijk maatregelen nemen teneinde zo spoedig mogelijk
					op de plaats van bestemming te kunnen arriveren.
				</li>
				<li>
					De Opdrachtgever garandeert het volgende:
					<ul style="list-style-type: lower-alpha">
						<li>
							In het geval van de uitvoering van een Opdracht in de open lucht een podium aanwezig is en dat dit podium
							deugdelijk, afgeschermd en overdekt is, opdat de weersomstandigheden redelijkerwijs geen schade kunnen
							toebrengen aan bijvoorbeeld betrokkenen en/of Zaken van de Artiest en/of The DJ Company.
						</li>
						<li>
							Indien de omstandigheden ten tijde van de uitvoering van de Opdracht dit noodzakelijk mochten maken dat
							Opdrachtgever in overleg met The DJ Company voor een professionele ordedienst ter plaatse zorgt.
						</li>
						<li>De ordehandhaving geregeld is tijdens opbouw, uitvoering en afbouw.</li>
						<li>
							Er is op de locatie sprake van een deugdelijke stroomvoorziening (minimaal 2 vrije groepen van 220 volt /
							16 ampère, tenzij anders schriftelijk overeengekomen). De stroomvoorziening mag niet meer dan 5 meter van
							de plaats van Opdracht verwijderd zijn en dient veilig en geaard te zijn. Elke schade als gevolg van een
							falende en/of ondeugdelijke stroomvoorziening zal door Opdrachtgever worden vergoed. The DJ Company kan
							nimmer aansprakelijk worden gesteld voor de gevolgen van een ondeugdelijke en/of falende
							stroomvoorziening.
						</li>
						<li>Indien voor laden/lossen een ontheffing nodig is, zal de Opdrachtgever hiervoor zorg dragen.</li>
						<li>
							Indien de afstand tussen de laad/losplaats en de locatie van de Opdracht een langere afstand betreft dan
							25 meter en/of indien de laadroute niet deugdelijk verhard is, dit tenminste 14 dagen voor datum Opdracht
							door Opdrachtgever aan The DJ Company zal worden opgegeven, er zal dan extra sjouwhulp en/of andere
							voorzorgsmaatregelen door The DJ Company worden verzorgd. Voor deze extra sjouwhulp en/of voorzieningen
							zal door The DJ Company extra kosten in rekening worden gebracht.
						</li>
						<li>
							Tenminste 14 dagen voor datum Opdracht de eventuele aanwezigheid van drie of meer te overbruggen
							traptreden van maximaal 20 centimeter per traptrede tussen de laad/losplaats en het podium door
							Opdrachtgever aan The DJ Company zijn doorgegeven. In geval er drie of meer traptreden moeten worden
							overbrugd tussen de laad/losplaats en de locatie van de Opdracht kan er een extra sjouwtoeslag door The DJ
							Company in rekening worden gebracht.
						</li>
						<li>
							Voor de Artiest is een parkeerplaats binnen een straal van 400 meter na het laden en lossen beschikbaar
							voor alle door Artiest en haar crew gebruikte vervoersmiddelen.
						</li>
						<li>
							Parkeerkosten en eventuele andere kosten (zoals voor overtocht op een boot) zijn voor rekening van The DJ
							Company en vallen dus onder de overeengekomen prijs, tenzij anders vermeld.
						</li>
						<li>
							Indien er op de locatie zand, water, schuim, confetti of andere (vloei)stoffen gebruikt worden die
							schadelijk kunnen zijn voor Zaken, is Opdrachtgever verplicht ervoor zorg te dragen dat deze
							(vloei)stoffen niet in aanraking kunnen komen met Zaken. Dit kan bijvoorbeeld door het plaatsen van een
							podium en/of dranghekken.
						</li>
						<li>
							Er sprake is van een toereikende verzekering voor de mogelijkerwijs uit de uitvoering van de Overeenkomst
							voortvloeiende risico’s.
						</li>
					</ul>
				</li>
				<li>
					Vertragingen of niet optreden, veroorzaakt doordat aan de eisen, gesteld in Artikel 5 kunnen nimmer leiden tot
					ontbinding van de Overeenkomst door de Opdrachtgever dan wel verhaal van enige schade op The DJ Company.
				</li>
				<li>
					De Opdrachtgever verklaart ermee bekend te zijn dat: a. Artiest tijdens de uitvoering per uur recht heeft op
					twee consumpties voor rekening van Opdrachtgever, tenzij vooraf anders schriftelijk is overeengekomen;
				</li>
				<li>
					Opdrachtgever staat garant voor de veiligheid van de Artiest ten tijde van de uitvoering van de Opdracht.
				</li>
				<li>
					Indien Opdrachtgever onvoldoende maatregelen neemt om een veilige uitvoering van een Opdracht te garanderen,
					is The DJ Company gerechtigd de Opdracht geheel of gedeeltelijk geen doorgang te laten vinden, zonder dat
					Opdrachtgever enige aanspraak kan maken op een schadevergoeding of korting op de met The DJ Company in de
					Overeenkomst genoemde vergoeding.
				</li>
				<li>
					Indien de Opdracht beperkt is tot de artistieke prestatie van een Discjockey als Artiest dient Opdrachtgever
					zelf zorg te dragen voor professionele geluidsapparatuur. Hieronder wordt minimaal het volgende verstaan:<br />
					- Een geluidsset met voldoende vermogen voor het verwachte gastenaantal;<br />
					- Twee CD spelers, type Pioneer CDJ-1000 MK3. Andere merken uitsluitend in overleg;<br />
					- Een DJ mengpaneel, type Pioneer DJM 800. Andere merken uitsluitend in overleg;<br />
					- Een microfoon, type Shure SM58;<br />
					- De apparatuur dient goed werkend en reeds aangesloten te zijn.<br />
					- Voor sommige items gelden aanvullende eisen.
				</li>
			</ol>
			<h5>Artikel 6. Overlast</h5>
			<ol>
				<li>
					The DJ Company is niet aansprakelijk voor het ontstaan van overlast of het overschrijden van maximum aantal
					decibel aan geluid.
				</li>
				<li>
					Bij een eerste waarschuwing inzake overlast van omwonenden of de politie dient de Opdrachtgever direct de
					Artiest en/of The DJ Company in te lichten ten einde passende maatregelen te treffen.
				</li>
				<li>
					The DJ Company is gerechtigd om bij waarschuwingen van omwonenden of de politie inzake overlast de Opdracht te
					annuleren of op te schorten.
				</li>
			</ol>
			<h5>Artikel 7. Prijzen en betalingen</h5>
			<ol>
				<li>
					Prijsopgaven worden steeds gedaan op basis van de op het tijdstip van de offerte en/of het sluiten der
					Overeenkomst geldende prijzen.
				</li>
				<li>
					Indien zich tussen het tijdstip van het sluiten van de Overeenkomst met de Opdrachtgever en het moment van
					nakoming van de verplichting uit hoofde van deze Overeenkomst aan de kant van The DJ Company prijsverhogingen
					- bijvoorbeeld met betrekking tot fiscale lasten, accijnzen, loonkosten, vervoerskosten, technische- en/of
					organisatorische kosten - mochten voordoen, is The DJ Company gerechtigd om deze kosten alsnog in rekening te
					brengen bij de Opdrachtgever. Indien voornoemde prijsverhogingen in geen verhouding staan tot de hoogte van de
					prijsopgaven ten tijde van het sluiten van de Overeenkomst, heeft ieder der Partijen het recht de Overeenkomst
					te ontbinden.
				</li>
				<li>Tenzij anders vermeld luiden alle prijsopgaven exclusief omzetbelasting.</li>
				<li>
					The DJ Company verzendt na de uitvoering van de Opdracht digitaal een factuur aan Opdrachtgever voor 100% van
					de in de Overeenkomst overeengekomen vergoeding voor de Opdracht. Indien er een waarborgsom betaald is, wordt
					dit bedrag in mindering gebracht op de factuur.
				</li>
				<li>
					Tenzij uitdrukkelijk anders van te voren schriftelijk is overeengekomen, dient betaling door de Opdrachtgever
					te geschieden uiterlijk 14 (veertien) dagen na datum van het verzenden van de factuur zonder dat de
					Opdrachtgever het recht heeft op verrekening en/of opschorting.
				</li>
				<li>
					Indien de Opdrachtgever niet tijdig aan al diens betalingsverplichtingen heeft voldaan is deze van rechtswege
					in verzuim. The DJ Company heeft alsdan het recht om zonder nadere aankondiging van de Opdrachtgever in rechte
					nakoming te vorderen.
				</li>
				<li>
					Bij niet, niet volledige of niet tijdige betaling is de Opdrachtgever voor iedere maand over het aan The DJ
					Company verschuldigde bedrag een rente verschuldigd gelijk de wettelijke rente verhoogd met een contractuele
					rente van 2% (twee procent) per maand, waarbij een gedeelte van een maand als een gehele maand wordt
					aangemerkt. Daarnaast is de Opdrachtgever in dat geval aan The DJ Company tevens de buitengerechtelijke
					incassokosten verschuldigd, welke 15% (vijftien procent) bedragen over het verschuldigde, doch met een minimum
					van € 250,- (tweehonderdvijftig euro).
				</li>
				<li>
					In het geval de Opdrachtgever in strijd handelt met dit artikel heeft het The DJ Company het recht diens
					verplichtingen jegens de Opdrachtgever op te schorten dan wel te annuleren
				</li>
				<li>
					In het geval er meer dan één Opdrachtgever partij bij de Overeenkomst is, is ieder der Opdrachtgevers jegens
					The DJ Company hoofdelijk gebonden aan de Overeenkomst.
				</li>
				<li>
					De Opdrachtgever verplicht zich jegens The DJ Company nimmer direct betalingen te verrichten aan de in de
					Overeenkomst betreffende Artiest.
				</li>
				<li>
					De Opdrachtgever is nimmer gerechtigd enige vordering welke hij op The DJ Company heeft, of denkt te hebben,
					te verrekenen met enige door hem aan The DJ Company verschuldigde factuur inzake een Opdracht of een deel
					daarvan.
				</li>
				<li>The DJ Company behoudt zich het recht voor bij iedere transactie zekerheidsstelling(-en) te vorderen.</li>
			</ol>
			<h5>Artikel 8. Annuleringsbepalingen</h5>
			<ol>
				<li>
					The DJ Company is gerechtigd de uitvoering van een Overeenkomst op te schorten dan wel te annuleren, indien en
					zodra The DJ Company gegronde vrees heeft om aan te nemen dat Opdrachtgever niet volledig aan zijn
					verplichtingen uit de Overeenkomst zal (kunnen) voldoen.
				</li>
				<li>
					Opdrachtgever is gerechtigd een Opdracht uit te stellen of te annuleren onder de volgende voorwaarden, waarbij
					Opdrachtgever verplicht is de door The DJ Company gemaakte kosten in verband met Opdracht te vergoeden,
					alsmede het navolgende percentage van het in de Overeenkomst vermelde uitkoopbedrag:
					<ol style="list-style-type: lower-alpha">
						<li>
							In geval van uitstel of annulering tot uiterlijk 14 dagen voor de productiedatum zullen er geen kosten in
							rekening worden gebracht.
						</li>
						<li>
							In geval van uitstel of annulering in de periode liggend tussen 13 dagen en 0 dagen voor de
							productiedatum, 100% van de in de Overeenkomst overeengekomen vergoeding.
						</li>
					</ol>
				</li>
				<li>
					Opdrachtgever aanvaardt haar aansprakelijkheid en vrijwaart The DJ Company volledig voor aanspraken van derden
					in verband met (gedeeltelijke) annulering van de Overeenkomst door Opdrachtgever.
				</li>
				<li>
					The DJ Company en/of de Artiest is/zijn bevoegd een Overeenkomst te annuleren indien er aanwijzingen bestaan
					dat de te houden bijeenkomst c.q. het te houden het feest waar de Artiest dient op te treden een zodanig ander
					karakter heeft dan verwacht mocht worden op grond van de door de Opdrachtgever verstrekte gegevens en/of
					inlichtingen of op grond van de hoedanigheid van de Opdrachtgever en/of zijn gasten dat The DJ Company en/of
					de Artiest de Overeenkomst niet gesloten zouden hebben indien zij van het werkelijke karakter op de hoogte
					waren geweest. The DJ Company en/of de Artiest is in geen geval jegens de Opdrachtgever en/of derden gehouden
					tot vergoeding van schade.
				</li>
			</ol>
			<h5>Artikel 9. Aansprakelijkheid</h5>
			<ol>
				<li>
					De Opdrachtgever is gehouden al die maatregelen te nemen die noodzakelijk zijn ter voorkoming of beperking van
					de schade.
				</li>
				<li>
					Opdrachtgever is aansprakelijk voor alle schade aan of diefstal van Zaken, gedurende de periode tussen het
					moment van aankomst en het moment van vertrek, een en ander voor zover deze schade niet is veroorzaakt door
					slijtage, fabricagefouten, The DJ Company of Artiest.
				</li>
				<li>
					The DJ Company is slechts aansprakelijk voor directe schade van de Opdrachtgever die rechtstreeks voortvloeit
					uit het niet, niet tijdig of niet behoorlijk nakomen van de Overeenkomst en voor zover als gevolg van opzet of
					grove schuld aan de zijde van The DJ Company. Voor gevolgschade, zoals winstderving, is The DJ Company nimmer
					aansprakelijk.
				</li>
				<li>
					In ieder geval is de aansprakelijkheid van The DJ Company beperkt tot de voor volledige uitvoering van de
					Opdracht afgesproken of redelijkerwijs te verwachten vergoeding van The DJ Company.
				</li>
				<li>
					Elke aanspraak van Opdrachtgever op vergoeding van schade dient per aangetekende brief aan The DJ Company
					kenbaar te zijn gemaakt binnen acht dagen na de dag, waarop Opdrachtgever zowel met de schade als met de
					aansprakelijkheid van The DJ Company bekend is geworden of redelijkerwijs bekend had kunnen zijn. Een
					rechtsvordering van Opdrachtgever tot vergoeding van schade vervalt in ieder geval door verloop van twaalf
					maanden na de gebeurtenis waardoor de schade is veroorzaakt.
				</li>
				<li>
					The DJ Company is niet aansprakelijk voor schade veroorzaakt door derden ingeschakeld ten behoeve van de
					uitvoering van een Opdracht. Opdrachtgever dient de schade in dat geval rechtstreeks op de betreffende
					derde(n)te verhalen.
				</li>
				<li>
					The DJ Company is in geen geval aansprakelijk voor enige schade als gevolg van het ontbreken van adequate en
					toereikende (kracht)stroomvoorzieningen, aggregaten, generatoren en zekeringen.
				</li>
				<li>
					Iedere schade toegebracht aan instrumenten, geluidsapparatuur e.d. van de Artiest zowel in eigendom als
					ingehuurd, veroorzaakt door het aanwezige publiek tijdens een Opdracht of veroorzaakt als gevolg van een
					falende stroomvoorziening, wordt door de Opdrachtgever volledig vergoed tegen dagwaarde vastgesteld door een
					erkend taxateur met aantoonbare expertise op het gebied van instrumenten en geluidsapparatuur. Betaling vindt
					plaats uiterlijk binnen een maand nadat de schade is toegebracht en de waarde is vastgesteld.
				</li>
			</ol>
			<h5>Artikel 10. Overmacht</h5>
			<ol>
				<li>
					Een tekortkoming wegens overmacht kan niet aan de tekortkomende Partij worden toegerekend, indien zij niet is
					te wijten aan zijn schuld, noch krachtens wet, rechtshandeling of in het verkeer geldende opvattingen voor
					zijn rekening komt.
				</li>
				<li>
					Onvoorziene omstandigheden, van welke aard dan ook, waardoor The DJ Company diens verplichtingen uit hoofde
					van de met de Opdrachtgever gesloten Overeenkomst niet, niet tijdig of niet zonder naar zijn oordeel
					onredelijk bezwarende extra inspanningen en/of kosten kan nakomen, zullen voor The DJ Company als overmacht
					gelden. Onder overmacht wordt mede verstaan:<br />
					- mobilisatie, oorlog, molest, terrorisme;<br />
					- een dag van nationale rouw;<br />
					- overheidsmaatregelen;<br />
					- werkstaking;<br />
					- natuurrampen;<br />
					- het niet, niet tijdig of niet behoorlijk voldoen door een derde, van wie The DJ Company voor de uitvoering
					van de Opdracht afhankelijk is, aan diens verplichtingen jegens The DJ Company.
				</li>
				<li>
					The DJ Company is, met inachtneming van de bepalingen aangaande Meerwerk, in geval van overmacht gerechtigd:
					<ol style="list-style-type: lower-alpha">
						<li>
							hetzij de Overeenkomst met de Opdrachtgever (gedeeltelijk) te ontbinden, zulks door een schriftelijke
							mededeling hiervan aan de Opdrachtgever, zonder dat The DJ Company hierdoor jegens de Opdrachtgever
							schadeplichtig wordt;
						</li>
						<li>
							hetzij, in overleg met de Opdrachtgever, de uitvoering van de Opdracht te verschuiven naar een nieuw
							tijdstip of nieuwe datum&nbsp;
						</li>
					</ol>
				</li>
				<li>
					Het komen te vervallen van de aanleiding tot de Opdracht van de Opdrachtgever, te weinig belangstelling,
					kaartverkoop of aanmeldingen voor een evenement waar de Opdracht op van toepassing is, slechte
					weersomstandigheden, ziekte van door de Opdrachtgever buiten The DJ Company om ingehuurde artiest(en), het
					niet verkrijgen of intrekking van de benodigde vergunningen of ontheffingen etc. vormen geen overmacht aan de
					zijde van de Opdrachtgever. Indien The DJ Company haar werkzaamheden op de overeengekomen dag niet kan
					uitvoeren als gevolg van in dit artikel genoemde of overige omstandigheden waardoor de Opdracht wordt
					afgelast, is dit voor rekening en risico van de Opdrachtgever. The DJ Company is alsdan gerechtigd tot
					invordering van de volledige betaling van de overeengekomen vergoeding.
				</li>
			</ol>
			<h5>Artikel 11. Huur en verhuur</h5>
			<ol>
				<li>
					Dit artikel is van toepassing op iedere tussen The DJ Company en de Opdrachtgever gesloten Overeenkomst inzake
					een Opdracht waarvan de verhuur van roerende Zaken onderdeel uitmaakt.
				</li>
				<li>
					Onder “het gehuurde” wordt in dit artikel verstaan de Zaken genoemd in artikel 1 van deze algemene
					voorwaarden.
				</li>
				<li>
					The DJ Company is gerechtigd een waarborgsom van 50% vast te stellen die de Opdrachtgever vóór ingang van de
					huurperiode aan The DJ Company dient te voldoen.
				</li>
				<li>
					Tenzij Partijen uitdrukkelijk schriftelijk anders zijn overeengekomen, zal The DJ Company het gehuurde op de
					locatie van de Opdrachtgever afleveren en het gehuurde na afloop van de gebruiksperiode bij de Opdrachtgever
					ophalen.
				</li>
				<li>
					De Opdrachtgever dient het gehuurde direct na ontvangst op gebreken en/of beschadigingen te controleren.
					Eventuele gebreken, beschadigingen e.d. dient de Opdrachtgever zo snel mogelijk - maar uiterlijk binnen 24 uur
					na ontvangst van het gehuurde - aan The DJ Company te melden, bij gebreke waarvan het gehuurde geacht wordt
					zonder gebreken en in onbeschadigde staat door de Opdrachtgever te zijn ontvangen.
				</li>
				<li>
					De Opdrachtgever is verplicht het gehuurde gedurende de huurperiode in goede staat te houden en is
					aansprakelijk voor alle tijdens de huurperiode ontstane schade, ook indien deze schade aan het gehuurde door
					de door de Opdrachtgever ingeschakelde derden of bezoekers van de Opdracht wordt veroorzaakt, alsmede voor
					verlies of diefstal. Schade, verlies en/of diefstal dient direct na ontstaan c.q. constatering aan The DJ
					Company te worden gemeld onder opgaaf van alle bijzonderheden.
				</li>
				<li>
					Herstel van schade of defecten aan c.q. vervanging van onderdelen van het gehuurde mag alleen worden
					uitgevoerd door The DJ Company of - na diens uitdrukkelijke toestemming - op aanwijzing van The DJ Company.
					Bij vervanging van defecte onderdelen zal de Opdrachtgever deze onderdelen bewaren en aan The DJ Company
					overhandigen bij terug levering van het gehuurde. Indien de Opdrachtgever deze onderdelen niet bewaart of
					indien de onderdelen niet defect blijken te zijn, zijn de kosten van vervanging van de onderdelen voor
					rekening van de Opdrachtgever.
				</li>
				<li>
					De Opdrachtgever is verplicht zorg te dragen voor een veilige opslag c.q. bewaring van het gehuurde gedurende
					de huurperiode. De Opdrachtgever zal te allen tijde aan The DJ Company of zijn gevolmachtigde toegang verlenen
					tot de locatie waar het gehuurde zich bevinden om de beveiliging en de opslag van het gehuurde te inspecteren.
					Maatregelen die naar het oordeel van The DJ Company nodig zijn om veilige opslag of goede beveiliging te
					garanderen zijn voor rekening van de Opdrachtgever.
				</li>
				<li>
					De Opdrachtgever dient het gehuurde gedurende de looptijd van de Overeenkomst in ieder geval te verzekeren
					voor de gebruikelijk risico’s, zoals schade, verlies en tenietgaan van het gehuurde. Indien het gehuurde
					verloren gaat of onherstelbare schade oploopt, is de Opdrachtgever een door The DJ Company te bepalen
					schadevergoeding verschuldigd, ter grootte van de nieuwwaarde van het gehuurde, vermeerderd met de kosten van
					het inhuren van vervangende Zaken of gemiste huuropbrengsten.
				</li>
				<li>
					Na afloop van de huurperiode dient de Opdrachtgever het gehuurde in de oorspronkelijke emballage en in de
					staat waarin het zich bij ontvangst bevond, bij The DJ Company af te leveren c.q. aan The DJ Company ter
					beschikking te stellen.
				</li>
				<li>
					The DJ Company zal het gehuurde na retournering direct inspecteren. De Opdrachtgever heeft het recht om bij
					deze inspectie aanwezig te zijn. Eventuele kosten in verband met tenietgaan of vermissing (van onderdelen) van
					het gehuurde evenals noodzakelijke kosten voor reiniging en reparatie, komen voor rekening van de
					Opdrachtgever.
				</li>
				<li>
					Indien Partijen zijn overeengekomen dat The DJ Company het gehuurde bij de Opdrachtgever aflevert, worden
					vertragingen ontstaan tijdens het laden, het transport en het lossen van het gehuurde, welke te wijten zijn
					aan omstandigheden die in redelijkheid voor rekening en risico van de Opdrachtgever komen, evenals de tijd die
					gemoeid is met reparaties die het gevolg zijn van nalatigheid van de Opdrachtgever, eveneens onder de
					huurperiode begrepen.
				</li>
				<li>
					Voor iedere vertraging in de teruggave van het gehuurde na het verstrijken van de in de Overeenkomst genoemde
					periode, is de Opdrachtgever een in redelijkheid door The DJ Company te bepalen vergoeding verschuldigd,
					waaronder begrepen de kosten van het inhuren van vervangende Zaken of gemiste huuropbrengsten, onverminderd
					het recht van The DJ Company op volledige schadevergoeding.
				</li>
				<li>
					Indien de Opdrachtgever het gehuurde - om welke reden dan ook - niet retourneert aan The DJ Company, is de
					Opdrachtgever verplicht alle hierdoor geleden schade van The DJ Company te vergoeden, waaronder de nieuwwaarde
					van het gehuurde, de kosten van het inhuren van vervangende Zaken en de gederfde winst.
				</li>
				<li>
					Het gehuurde blijft altijd eigendom van The DJ Company. Het is de Opdrachtgever niet toegestaan het gehuurde
					onder te verhuren, aan derden in gebruik te geven, derden rechten te verstrekken op het gehuurde, onderdelen
					te vervangen of overige wijzigingen op of aan het gehuurde aan te brengen anders dan na voorafgaande
					schriftelijke toestemming van The DJ Company. De Opdrachtgever dient te allen tijde te voorkomen dat bij
					derden de verwachting of indruk wordt gewekt, dat hij tot verdere beschikking over het gehuurde bevoegd is.
				</li>
				<li>
					Indien er sprake is van beslaglegging op het gehuurde - daaronder begrepen fiscaal bodembeslag - of indien
					gegronde vrees bestaat dat dit zal geschieden, dient de Opdrachtgever dit onverwijld aan The DJ Company te
					melden en de beslaglegger direct te informeren dat het gehuurde eigendom is van The DJ Company.
				</li>
				<li>
					The DJ Company is gerechtigd alle voor rekening van de Opdrachtgever komende kosten en schade, voor zover dit
					mogelijk is, te verrekenen met de van de Opdrachtgever ontvangen waarborgsom.
				</li>
			</ol>
			<h5>Artikel 12. Toepasselijk recht</h5>
			<ol>
				<li>
					Alle geschillen welke voortvloeien uit de Overeenkomst tussen The DJ Company en Opdrachtgever zullen worden
					voorgelegd aan een mediator. Indien de bemiddelingspoging van de mediator niet leidt tot een voor beide
					Partijen aanvaardbare oplossing dan zal de kwestie voorgelegd worden aan de bevoegde rechter in het
					arrondissement alwaar The DJ Company op dat moment is gevestigd.
				</li>
				<li>Op de rechtsverhouding tussen partijen is Nederlands recht van toepassing.</li>
			</ol>
			<p>
				Wij hopen dat alle voorwaarden voor u duidelijk zijn. Mocht dit niet het geval zijn, neem dan vooral contact met
				ons op. Wij staan u graag te woord!
			</p>
			<p>
				Met muzikale groeten,<br />
				The DJ Company Team
			</p>
		</span>
		<span v-if="isBelgian">
			<h1>Algemene voorwaarden van The DJ Company</h1>
			<p>
				Geachte lezer, Voor u liggen de algemene voorwaarden van The DJ Company. Omdat algemene voorwaarden door
				juristen worden geschreven is de tekst erg technisch. In feite zijn de voorwaarden niets anders dan een afspraak
				tussen u en ons, en komt deze simpelweg neer op het volgende:
			</p>
			<ol>
				<li>
					Wij doen ons uiterste best om onze afspraken na te komen. Wij moeten het hebben van mond-tot-mond reclame en
					zullen er alles aan doen om er een prachtig feest van te maken. Uw wensen zijn hierbij altijd leidend.
				</li>
				<li>
					Wij spreken met u 1 prijs af en u mag ervan uitgaan dat alle logische kosten hieronder vallen. Hierdoor komt u
					achteraf niet voor verrassingen te staan.
				</li>
				<li>U zorgt ervoor dat wij ons werk kunnen doen en dat onze apparatuur naar behoren beschermd is.</li>
				<li>
					U brengt ons zo goed mogelijk op de hoogte van alle aspecten van uw feest: we staan dan niet voor
					verrassingen.
				</li>
			</ol>
			<p>
				Samen nemen wij onze verantwoordelijkheden en zorgen we er voor dat we uw gelegenheid tot een succes maken. Wij
				hoeven ons dan ook nooit te beroepen op deze algemene voorwaarden. Dit blijkt ook wel uit het grote aantal
				tevreden klanten die ons hun feest toevertrouwden. Lees hier over
				<router-link class="ahref" :to="{name: 'Reviews'}">onze tevreden klanten</router-link>.
			</p>
			<p>
				<a
					href="https://thedjcompany.be/download/nl-be/20240101/algemene-voorwaarden.pdf"
					rel="noreferrer noopener"
					target="_blank"
					><font-awesome-icon :icon="['far', 'file-pdf']" class="mr-2" />Download onze algemene voorwaarden in
					PDF-formaat</a
				>
			</p>
			<h2>Algemene voorwaarden The DJ Company</h2>
			<h2>Artikel 1. Definities</h2>
			<p>
				In deze algemene voorwaarden en alle Overeenkomsten waarop deze algemene voorwaarden van toepassing zijn wordt
				verstaan:
			</p>
			<ol>
				<li>
					Onder “The DJ Company” : The DJ Company BV, gevestigd te Groningen en zoals ingeschreven bij de Kamer van
					Koophandel te Groningen onder nummer 73421413.
				</li>
				<li>
					Onder “Opdrachtgever” : de (rechts)persoon die Opdracht verleent aan The DJ Company tot het engageren van
					artiesten en/of musici en/of het verhuren van apparatuur en/of het organiseren van een evenement of
					festiviteit.
				</li>
				<li>
					Onder “Artiest” : de (beroeps)beoefenaar alleen of in groepsverband op het gebied van kunsten en amusement,
					die zich jegens The DJ Company heeft verbonden een bepaalde artistieke prestatie te verrichten. Onder
					(beroeps)beoefenaar wordt verstaan een diskjockey en/of artiest en/of musicus en/of presentator, die tegen
					betaling deelneemt aan voorstellingen, concerten en/of uitvoeringen en/of degene die tegen betaling directe
					technische of artistieke bijstand verleent ten behoeve van de onder Artikel 1. onder 2. bedoelde evenementen
					of festiviteiten.
				</li>
				<li>
					Onder “Opdracht” : de door de Opdrachtgever aan The DJ Company verstrekte Opdracht welke, in de ruimste zin
					van het woord, betrekking heeft op het verzorgen van artiesten en/of het verhuren en/of het in bruikleen geven
					van Zaken en / of het leveren van aanvullende en/of andere diensten en/of goederen als omschreven in de
					Overeenkomst.
				</li>
				<li>
					Onder “Overeenkomst” : de tussen The DJ Company en Opdrachtgever tot stand gekomen Overeenkomst tot Opdracht.
				</li>
				<li>Onder “Partijen” : alle betrokken partijen, te weten Artiest, The DJ Company en Opdrachtgever.</li>
				<li>
					Onder “Meerwerk”, alle wijzigingen/aanvullingen in, op of voortvloeiende uit de met de Opdrachtgever gesloten
					Overeenkomst, waardoor The DJ Company en/of de door haar ingeschakelde Toeleveranciers en/of Artiest meer dan
					wel gewijzigde werkzaamheden moeten verrichten en/of hogere (on)kosten moeten maken.
				</li>
				<li>
					Onder “Zaken”, de door The DJ Company voor het uitvoeren van de Opdracht gebruikte Zaken c.q. de ten behoeve
					van de uitvoering van de Opdracht aan de Opdrachtgever verhuurde of anderszins in gebruik gegeven Zaken, zoals
					geluids-, beeld- en lichtapparatuur, bekabeling, verpakkingsmaterialen, decorstukken, podiumdelen e.d.
				</li>
			</ol>
			<h2>Artikel 2. Toepasselijkheid</h2>
			<ol>
				<li>Deze algemene voorwaarden maken deel uit van alle offertes en Overeenkomsten met The DJ Company.</li>
				<li>
					Afwijkende bedingen en eventuele algemene voorwaarden van Opdrachtgever gelden slechts indien en voor zover
					deze uitdrukkelijk door The DJ Company schriftelijk zijn aanvaard.
				</li>
				<li>
					Bij sommige Opdrachten zijn aanvullende voorwaarden van toepassing. Deze zullen in dat geval bij de
					Overeenkomst verstrekt worden.
				</li>
				<li>
					Deze voorwaarden worden door The DJ Company bij de verstrekking van een offerte en bij de bevestiging van een
					Opdracht digitaal per e-mail verzonden aan Opdrachtgever in de vorm van een hyperlink naar een digitale versie
					van deze voorwaarden in de offerte dan wel de bevestiging van een Opdracht. Deze voorwaarden zijn tevens via
					internet te raadplegen op www.thedjcompany.be.
				</li>
				<li>
					Indien en voor zover bij Overeenkomst is afgeweken van deze algemene voorwaarden, prevaleert het bepaalde in
					de Overeenkomst.
				</li>
			</ol>
			<h2>Artikel 3. Totstandkoming Overeenkomsten en offertes</h2>
			<ol>
				<li>
					Alle offertes van The DJ Company, zowel schriftelijk als mondeling, zijn steeds vrijblijvend en hebben een
					geldigheid die staat vermeld op de offerte
				</li>
				<li>
					Door ondertekening van de offerte gaat Opdrachtgever een Overeenkomst aan met The DJ Company inzake de
					Opdracht genoemd in de offerte. Opdrachtgever en The DJ Company hebben in deze Overeenkomst in ieder geval
					overeenstemming bereikt over: optredende Artiest(en), datum en tijdstippen Opdracht, vergoeding, locatie en
					overige dienstverlening.
				</li>
				<li>
					The DJ Company en Opdrachtgever zijn gebonden nadat Opdrachtgever de offerte inzake de Opdracht voorziet van
					handtekening en als zodanig aan The DJ Company retourneert, per e-mail of fax. Of na het online bevestigen per
					online link.
				</li>
				<li>
					Eventueel na ondertekening van de offerte te maken aanvullende of gewijzigde afspraken inzake de Opdracht zijn
					slechts van kracht indien deze door The DJ Company en Opdrachtgever schriftelijk zijn overeengekomen.
				</li>
				<li>
					Opdrachtgever heeft het recht om tot 14 dagen tot de uitvoerdatum kosteloos de Overeenkomst schriftelijk te
					annuleren, tenzij anders vermeld op de Overeenkomst.&nbsp;
				</li>
				<li>
					Bij een eventuele annulering na de in punt 5 genoemde datum is de Overeenkomst definitief tot stand gekomen en
					zijn de in deze algemene voorwaarden genoemde annuleringsbepalingen van kracht.
				</li>
				<li>
					Opdrachtgever en The DJ Company erkennen elektronische communicatie en zullen de geldigheid en het juridisch
					effect daarvan niet ontzeggen vanwege het enkele feit dat de communicatie elektronisch is.
				</li>
				<li>
					Meerwerk wordt afzonderlijk in rekening gebracht. Meerwerk wordt berekend op de grondslag van de bij het
					aangaan van de Overeenkomst gestelde condities. Minderwerk wordt slechts verrekend indien Partijen dit van
					tevoren schriftelijk zijn overeengekomen.
				</li>
				<li>
					Beeldmateriaal (mondeling, telefonisch, via e-mail verstrekt of op de website vermeld) van The DJ Company met
					betrekking tot alle offertes en de belangrijkste kenmerken van de Opdracht worden zo nauwkeurig mogelijk
					(weer)gegeven of gedaan. The DJ Company garandeert echter niet dat alle offertes en producten volledig met het
					gegeven beeldmateriaal et cetera in overeenstemming zijn. Afwijkingen kunnen in beginsel geen aanleiding zijn
					tot schadevergoeding en/of ontbinding.
				</li>
			</ol>
			<h2>Artikel 4. Uitvoering Overeenkomst</h2>
			<ol>
				<li>The DJ Company zal elke Overeenkomst naar beste kunnen uitvoeren.</li>
				<li>
					The DJ Company is gerechtigd bij en voor de uitvoering van een Overeenkomst derden (externe leveranciers) in
					te schakelen. Hoewel deze derden zorgvuldig geselecteerd en gecontracteerd worden kan The DJ Company niet
					aansprakelijk gesteld worden voor het handelen en nalaten van deze derden. The DJ Company verplicht zich
					echter wel een probleem dat ontstaan is door handelen of nalaten van door The DJ Company ingeschakelde derden
					naar beste kunnen op te lossen. Hiermee wordt o.a. het regelen van een gelijkwaardige vervanging van Artiest
					bij ziekte van de in de Overeenkomst beschreven Opdracht bedoeld.
				</li>
				<li>
					Indien op verzoek van Opdrachtgever wordt afgeweken van hetgeen oorspronkelijk werd overeengekomen zullen de
					meerkosten daarvan aan Opdrachtgever worden doorberekend. Hiermee wordt o.a. verlenging van de Opdracht
					bedoeld.
				</li>
				<li>
					Geringe afwijkingen in de uitvoering, ook in esthetische zin, geeft Opdrachtgever geen recht op korting op het
					de in Overeenkomst afgesproken vergoeding.
				</li>
			</ol>
			<h2>Artikel 5. Verantwoordelijkheden Opdrachtgever</h2>
			<ol>
				<li>
					Artiest is verplicht tijdig aanwezig te zijn, met alle Zaken die voor de Opdracht nodig zijn, tenzij
					schriftelijk van te voren is overeengekomen dat bepaalde voor de Opdracht benodigde Zaken door The DJ Company
					en/of de Opdrachtgever zullen worden verstrekt.
				</li>
				<li>
					Opdrachtgever verklaart, reeds door het aangaan van de Overeenkomst met The DJ Company, volledig bekend te
					zijn met de prestaties van de Artiest en, alsmede met de soort en/of de aard van de concreet overeengekomen
					Opdracht.
				</li>
				<li>
					Opdrachtgever dient zelf zorg te dragen voor de toestemming van derden of vergunningen die benodigd zijn voor
					uitvoering van de met The DJ Company in de Overeenkomst omschreven Opdracht. Opdrachtgever dient te voldoen
					aan alle (wettelijke) eisen en voorschriften.
				</li>
				<li>
					Opdrachtgever is zelf verantwoordelijk voor het handelen en nalaten van de bezoekers van een krachtens een
					Overeenkomst door The DJ Company uitgevoerde Opdracht.
				</li>
				<li>
					Opdrachtgever zal The DJ Company op de hoogte stellen van eventuele omleidingen, wegopbrekingen en/of
					-afsluitingen die de directe toegang tot de locatie van de Opdracht kunnen belemmeren. Vertragingen of niet
					optreden, veroorzaakt doordat aan deze eisen niet of onvoldoende wordt voldaan, kan nimmer leiden tot
					ontbinding van de Overeenkomst door de Opdrachtgever dan wel verhaal van enige schade op The DJ Company.
				</li>
				<li>
					Indien Artiest op weg naar de Opdracht oponthoud heeft of verwacht te krijgen, zal Opdrachtgever direct
					gewaarschuwd worden. Opdrachtgever zal voor dit doel een bereikbaar telefoonnummer ter beschikking stellen.
					Artiest zal, voor zover redelijkerwijs mogelijk, onmiddellijk maatregelen nemen teneinde zo spoedig mogelijk
					op de plaats van bestemming te kunnen arriveren.
				</li>
				<li>
					De Opdrachtgever garandeert het volgende:
					<ol>
						<li>
							In het geval van de uitvoering van een Opdracht in de open lucht een podium aanwezig is en dat dit podium
							deugdelijk, afgeschermd en overdekt is, opdat de weersomstandigheden redelijkerwijs geen schade kunnen
							toebrengen aan bijvoorbeeld betrokkenen en/of Zaken van de Artiest en/of The DJ Company.
						</li>
						<li>
							Indien de omstandigheden ten tijde van de uitvoering van de Opdracht dit noodzakelijk mochten maken dat
							Opdrachtgever in overleg met The DJ Company voor een professionele ordedienst ter plaatse zorgt.
						</li>
						<li>De ordehandhaving geregeld is tijdens opbouw, uitvoering en afbouw.</li>
						<li>
							Er is op de locatie sprake van een deugdelijke stroomvoorziening (minimaal 2 vrije groepen van 220 volt /
							16 ampère, tenzij anders schriftelijk overeengekomen). De stroomvoorziening mag niet meer dan 5 meter van
							de plaats van Opdracht verwijderd zijn en dient veilig en geaard te zijn. Elke schade als gevolg van een
							falende en/of ondeugdelijke stroomvoorziening zal door Opdrachtgever worden vergoed. The DJ Company kan
							nimmer aansprakelijk worden gesteld voor de gevolgen van een ondeugdelijke en/of falende
							stroomvoorziening.
						</li>
						<li>Indien voor laden/lossen een ontheffing nodig is, zal de Opdrachtgever hiervoor zorg dragen.</li>
						<li>
							Indien de afstand tussen de laad/losplaats en de locatie van de Opdracht een langere afstand betreft dan
							25 meter en/of indien de laadroute niet deugdelijk verhard is, dit tenminste 14 dagen voor datum Opdracht
							door Opdrachtgever aan The DJ Company zal worden opgegeven, er zal dan extra sjouwhulp en/of andere
							voorzorgsmaatregelen door The DJ Company worden verzorgd. Voor deze extra sjouwhulp en/of voorzieningen
							zal door The DJ Company extra kosten in rekening worden gebracht.
						</li>
						<li>
							Tenminste 14 dagen voor datum Opdracht de eventuele aanwezigheid van drie of meer te overbruggen
							traptreden van maximaal 20 centimeter per traptrede tussen de laad/losplaats en het podium door
							Opdrachtgever aan The DJ Company zijn doorgegeven. In geval er drie of meer traptreden moeten worden
							overbrugd tussen de laad/losplaats en de locatie van de Opdracht kan er een extra sjouwtoeslag door The DJ
							Company in rekening worden gebracht.
						</li>
						<li>
							Voor de Artiest is een parkeerplaats binnen een straal van 400 meter na het laden en lossen beschikbaar
							voor alle door Artiest en haar crew gebruikte vervoersmiddelen.
						</li>
						<li>
							Parkeerkosten en eventuele andere kosten (zoals voor overtocht op een boot) zijn voor rekening van The DJ
							Company en vallen dus onder de overeengekomen prijs, tenzij anders vermeld.
						</li>
						<li>
							Indien er op de locatie zand, water, schuim, confetti of andere (vloei)stoffen gebruikt worden die
							schadelijk kunnen zijn voor Zaken, is Opdrachtgever verplicht ervoor zorg te dragen dat deze
							(vloei)stoffen niet in aanraking kunnen komen met Zaken. Dit kan bijvoorbeeld door het plaatsen van een
							podium en/of dranghekken.
						</li>
						<li>
							Er sprake is van een toereikende verzekering voor de mogelijkerwijs uit de uitvoering van de Overeenkomst
							voortvloeiende risico’s.
						</li>
					</ol>
				</li>
				<li>
					Vertragingen of niet optreden, veroorzaakt doordat aan de eisen, gesteld in Artikel 5 kunnen nimmer leiden tot
					ontbinding van de Overeenkomst door de Opdrachtgever dan wel verhaal van enige schade op The DJ Company.
				</li>
				<li>
					De Opdrachtgever verklaart ermee bekend te zijn dat: a. Artiest tijdens de uitvoering per uur recht heeft op
					twee consumpties voor rekening van Opdrachtgever, tenzij vooraf anders schriftelijk is overeengekomen;
				</li>
				<li>
					Opdrachtgever staat garant voor de veiligheid van de Artiest ten tijde van de uitvoering van de Opdracht.
				</li>
				<li>
					Indien Opdrachtgever onvoldoende maatregelen neemt om een veilige uitvoering van een Opdracht te garanderen,
					is The DJ Company gerechtigd de Opdracht geheel of gedeeltelijk geen doorgang te laten vinden, zonder dat
					Opdrachtgever enige aanspraak kan maken op een schadevergoeding of korting op de met The DJ Company in de
					Overeenkomst genoemde vergoeding.
				</li>
				<li>
					Indien de Opdracht beperkt is tot de artistieke prestatie van een Discjockey als Artiest dient Opdrachtgever
					zelf zorg te dragen voor professionele geluidsapparatuur. Hieronder wordt minimaal het volgende verstaan:<br />
					- Een geluidsset met voldoende vermogen voor het verwachte gastenaantal;<br />
					- Twee CD spelers, type Pioneer CDJ-1000 MK3. Andere merken uitsluitend in overleg;<br />
					- Een DJ mengpaneel, type Pioneer DJM 800. Andere merken uitsluitend in overleg;<br />
					- Een microfoon, type Shure SM58;<br />
					- De apparatuur dient goed werkend en reeds aangesloten te zijn.<br />
					- Voor sommige items gelden aanvullende eisen.
				</li>
			</ol>
			<h2>Artikel 6. Overlast</h2>
			<ol>
				<li>
					The DJ Company is niet aansprakelijk voor het ontstaan van overlast of het overschrijden van maximum aantal
					decibel aan geluid.
				</li>
				<li>
					Bij een eerste waarschuwing inzake overlast van omwonenden of de politie dient de Opdrachtgever direct de
					Artiest en/of The DJ Company in te lichten ten einde passende maatregelen te treffen.
				</li>
				<li>
					The DJ Company is gerechtigd om bij waarschuwingen van omwonenden of de politie inzake overlast de Opdracht te
					annuleren of op te schorten.
				</li>
			</ol>
			<h2>Artikel 7. Prijzen en betalingen</h2>
			<ol>
				<li>
					Prijsopgaven worden steeds gedaan op basis van de op het tijdstip van de offerte en/of het sluiten der
					Overeenkomst geldende prijzen.
				</li>
				<li>
					Indien zich tussen het tijdstip van het sluiten van de Overeenkomst met de Opdrachtgever en het moment van
					nakoming van de verplichting uit hoofde van deze Overeenkomst aan de kant van The DJ Company prijsverhogingen
					- bijvoorbeeld met betrekking tot fiscale lasten, accijnzen, loonkosten, vervoerskosten, technische- en/of
					organisatorische kosten - mochten voordoen, is The DJ Company gerechtigd om deze kosten alsnog in rekening te
					brengen bij de Opdrachtgever. Indien voornoemde prijsverhogingen in geen verhouding staan tot de hoogte van de
					prijsopgaven ten tijde van het sluiten van de Overeenkomst, heeft ieder der Partijen het recht de Overeenkomst
					te ontbinden.
				</li>
				<li>Tenzij anders vermeld luiden alle prijsopgaven exclusief omzetbelasting.</li>
				<li>
					The DJ Company verzendt na de uitvoering van de Opdracht digitaal een factuur aan Opdrachtgever voor 100% van
					de in de Overeenkomst overeengekomen vergoeding voor de Opdracht. Indien er een waarborgsom betaald is, wordt
					dit bedrag in mindering gebracht op de factuur.
				</li>
				<li>
					Tenzij uitdrukkelijk anders van te voren schriftelijk is overeengekomen, dient betaling door de Opdrachtgever
					te geschieden uiterlijk 14 (veertien) dagen na datum van het verzenden van de factuur zonder dat de
					Opdrachtgever het recht heeft op verrekening en/of opschorting.
				</li>
				<li>
					Het overeengekomen bedrag kan op de volgende manieren worden betaald:&nbsp;
					<ul>
						<li>Bancontact</li>
						<li>Bankoverschrijving</li>
					</ul>
				</li>
				<li>
					Indien de Opdrachtgever niet tijdig aan al diens betalingsverplichtingen heeft voldaan is deze van rechtswege
					in verzuim. The DJ Company heeft alsdan het recht om zonder nadere aankondiging van de Opdrachtgever in rechte
					nakoming te vorderen.
				</li>
				<li>
					In het geval de Opdrachtgever in strijd handelt met dit artikel heeft het The DJ Company het recht diens
					verplichtingen jegens de Opdrachtgever op te schorten dan wel te annuleren
				</li>
				<li>
					In het geval er meer dan één Opdrachtgever partij bij de Overeenkomst is, is ieder der Opdrachtgevers jegens
					The DJ Company hoofdelijk gebonden aan de Overeenkomst.
				</li>
				<li>
					De Opdrachtgever verplicht zich jegens The DJ Company nimmer direct betalingen te verrichten aan de in de
					Overeenkomst betreffende Artiest.
				</li>
				<li>
					De Opdrachtgever is nimmer gerechtigd enige vordering welke hij op The DJ Company heeft, of denkt te hebben,
					te verrekenen met enige door hem aan The DJ Company verschuldigde factuur inzake een Opdracht of een deel
					daarvan.
				</li>
				<li>The DJ Company behoudt zich het recht voor bij iedere transactie zekerheidsstelling(-en) te vorderen.</li>
			</ol>
			<h2>Artikel 8. Annuleringsbepalingen en herroepingsrecht</h2>
			<ol>
				<li>
					The DJ Company is gerechtigd de uitvoering van een Overeenkomst op te schorten dan wel te annuleren, indien en
					zodra The DJ Company gegronde vrees heeft om aan te nemen dat Opdrachtgever niet volledig aan zijn
					verplichtingen uit de Overeenkomst zal (kunnen) voldoen.
				</li>
				<li>
					Opdrachtgever is gerechtigd een Opdracht uit te stellen of te annuleren onder de volgende voorwaarden, waarbij
					Opdrachtgever verplicht is de door The DJ Company gemaakte kosten in verband met Opdracht te vergoeden,
					alsmede het navolgende percentage van het in de Overeenkomst vermelde uitkoopbedrag:
					<ol>
						<li>
							In geval van uitstel of annulering tot uiterlijk 14 dagen voor de productiedatum zullen er geen kosten in
							rekening worden gebracht.
						</li>
						<li>
							In geval van uitstel of annulering in de periode liggend tussen 13 dagen en 0 dagen voor de
							productiedatum, 100% van de in de Overeenkomst overeengekomen vergoeding.
						</li>
					</ol>
				</li>
				<li>
					De opdrachtgever kan een overeenkomst met betrekking tot de aankoop van een product gedurende een bedenktijd
					van 30 dagen zonder opgave van redenen ontbinden. The DJ Company mag de opdrachtgever vragen naar de reden van
					herroeping, maar deze niet tot opgave van zijn reden(en) verplichten. Om uw herroepingsrecht uit te oefenen,
					kunt u gebruik maken van de volgende
					<a
						href="https://economie.fgov.be/sites/default/files/Files/Forms/Formulier-herroeping.pdf"
						rel="noreferrer noopener"
						target="_blank"
						>brief</a
					>
					en deze versturen naar <a href="mailto:info@thedjcompany.nl">info@thedjcompany.be</a>. Indien er gebruik wordt
					gemaakt van het herroepingsrecht, vindt de terugbetaling van het overeengekomen bedrag uiterlijk binnen 14
					dagen plaats na de dag waarop The DJ Company is geïnformeerd door de de opdrachtgever.
				</li>
				<li>
					Wanneer de opdrachtgever gebruik maakt van het herroepingsrecht betaald The DJ Company met hetzelfde
					betaalmiddel dat tijdens de oorspronkelijke transactie werd gebruikt. The DJ Company gebruikt enkel een ander
					betaalmiddel wanneer de opdrachtgever hier uitdrukkelijk mee heeft ingestemd en dit geen extra kosten met zich
					meebrengt.
				</li>
				<li>
					Opdrachtgever aanvaardt haar aansprakelijkheid en vrijwaart The DJ Company volledig voor aanspraken van derden
					in verband met (gedeeltelijke) annulering van de Overeenkomst door Opdrachtgever.
				</li>
				<li>
					The DJ Company en/of de Artiest is/zijn bevoegd een Overeenkomst te annuleren indien er aanwijzingen bestaan
					dat de te houden bijeenkomst c.q. het te houden het feest waar de Artiest dient op te treden een zodanig ander
					karakter heeft dan verwacht mocht worden op grond van de door de Opdrachtgever verstrekte gegevens en/of
					inlichtingen of op grond van de hoedanigheid van de Opdrachtgever en/of zijn gasten dat The DJ Company en/of
					de Artiest de Overeenkomst niet gesloten zouden hebben indien zij van het werkelijke karakter op de hoogte
					waren geweest. The DJ Company en/of de Artiest is in geen geval jegens de Opdrachtgever en/of derden gehouden
					tot vergoeding van schade.
				</li>
			</ol>
			<h2>Artikel 9. Aansprakelijkheid</h2>
			<ol>
				<li>
					De Opdrachtgever is gehouden al die maatregelen te nemen die noodzakelijk zijn ter voorkoming of beperking van
					de schade.
				</li>
				<li>
					Opdrachtgever is aansprakelijk voor alle schade aan of diefstal van Zaken, gedurende de periode tussen het
					moment van aankomst en het moment van vertrek, een en ander voor zover deze schade niet is veroorzaakt door
					slijtage, fabricagefouten, The DJ Company of Artiest.
				</li>
				<li>
					The DJ Company is slechts aansprakelijk voor directe schade van de Opdrachtgever die rechtstreeks voortvloeit
					uit het niet, niet tijdig of niet behoorlijk nakomen van de Overeenkomst en voor zover als gevolg van opzet of
					grove schuld aan de zijde van The DJ Company. Voor gevolgschade, zoals winstderving, is The DJ Company nimmer
					aansprakelijk.
				</li>
				<li>
					In ieder geval is de aansprakelijkheid van The DJ Company beperkt tot de voor volledige uitvoering van de
					Opdracht afgesproken of redelijkerwijs te verwachten vergoeding van The DJ Company.
				</li>
				<li>
					Elke aanspraak van Opdrachtgever op vergoeding van schade dient per aangetekende brief aan The DJ Company
					kenbaar te zijn gemaakt binnen acht dagen na de dag, waarop Opdrachtgever zowel met de schade als met de
					aansprakelijkheid van The DJ Company bekend is geworden of redelijkerwijs bekend had kunnen zijn. Een
					rechtsvordering van Opdrachtgever tot vergoeding van schade vervalt in ieder geval door verloop van twaalf
					maanden na de gebeurtenis waardoor de schade is veroorzaakt.
				</li>
				<li>
					The DJ Company is niet aansprakelijk voor schade veroorzaakt door derden ingeschakeld ten behoeve van de
					uitvoering van een Opdracht. Opdrachtgever dient de schade in dat geval rechtstreeks op de betreffende
					derde(n)te verhalen.
				</li>
				<li>
					The DJ Company is in geen geval aansprakelijk voor enige schade als gevolg van het ontbreken van adequate en
					toereikende (kracht)stroomvoorzieningen, aggregaten, generatoren en zekeringen.
				</li>
				<li>
					Iedere schade toegebracht aan instrumenten, geluidsapparatuur e.d. van de Artiest zowel in eigendom als
					ingehuurd, veroorzaakt door het aanwezige publiek tijdens een Opdracht of veroorzaakt als gevolg van een
					falende stroomvoorziening, wordt door de Opdrachtgever volledig vergoed tegen dagwaarde vastgesteld door een
					erkend taxateur met aantoonbare expertise op het gebied van instrumenten en geluidsapparatuur. Betaling vindt
					plaats uiterlijk binnen een maand nadat de schade is toegebracht en de waarde is vastgesteld.
				</li>
			</ol>
			<h2>Artikel 10. Overmacht</h2>
			<ol>
				<li>
					Een tekortkoming wegens overmacht kan niet aan de tekortkomende Partij worden toegerekend, indien zij niet is
					te wijten aan zijn schuld, noch krachtens wet, rechtshandeling of in het verkeer geldende opvattingen voor
					zijn rekening komt.
				</li>
				<li>
					Onvoorziene omstandigheden, van welke aard dan ook, waardoor The DJ Company diens verplichtingen uit hoofde
					van de met de Opdrachtgever gesloten Overeenkomst niet, niet tijdig of niet zonder naar zijn oordeel
					onredelijk bezwarende extra inspanningen en/of kosten kan nakomen, zullen voor The DJ Company als overmacht
					gelden. Onder overmacht wordt mede verstaan:<br />
					- mobilisatie, oorlog, molest, terrorisme;<br />
					- een dag van nationale rouw;<br />
					- overheidsmaatregelen;<br />
					- werkstaking;<br />
					- natuurrampen;<br />
					- het niet, niet tijdig of niet behoorlijk voldoen door een derde, van wie The DJ Company voor de uitvoering
					van de Opdracht afhankelijk is, aan diens verplichtingen jegens The DJ Company.
				</li>
				<li>
					The DJ Company is, met inachtneming van de bepalingen aangaande Meerwerk, in geval van overmacht gerechtigd:
					<ol>
						<li>
							hetzij de Overeenkomst met de Opdrachtgever (gedeeltelijk) te ontbinden, zulks door een schriftelijke
							mededeling hiervan aan de Opdrachtgever, zonder dat The DJ Company hierdoor jegens de Opdrachtgever
							schadeplichtig wordt;
						</li>
						<li>
							hetzij, in overleg met de Opdrachtgever, de uitvoering van de Opdracht te verschuiven naar een nieuw
							tijdstip of nieuwe datum&nbsp;
						</li>
					</ol>
				</li>
				<li>
					Het komen te vervallen van de aanleiding tot de Opdracht van de Opdrachtgever, te weinig belangstelling,
					kaartverkoop of aanmeldingen voor een evenement waar de Opdracht op van toepassing is, slechte
					weersomstandigheden, ziekte van door de Opdrachtgever buiten The DJ Company om ingehuurde artiest(en), het
					niet verkrijgen of intrekking van de benodigde vergunningen of ontheffingen etc. vormen geen overmacht aan de
					zijde van de Opdrachtgever. Indien The DJ Company haar werkzaamheden op de overeengekomen dag niet kan
					uitvoeren als gevolg van in dit artikel genoemde of overige omstandigheden waardoor de Opdracht wordt
					afgelast, is dit voor rekening en risico van de Opdrachtgever. The DJ Company is alsdan gerechtigd tot
					invordering van de volledige betaling van de overeengekomen vergoeding.
				</li>
			</ol>
			<h2>Artikel 11. Huur en verhuur</h2>
			<ol>
				<li>
					Dit artikel is van toepassing op iedere tussen The DJ Company en de Opdrachtgever gesloten Overeenkomst inzake
					een Opdracht waarvan de verhuur van roerende Zaken onderdeel uitmaakt.
				</li>
				<li>
					Onder “het gehuurde” wordt in dit artikel verstaan de Zaken genoemd in artikel 1 van deze algemene
					voorwaarden.
				</li>
				<li>
					The DJ Company is gerechtigd een waarborgsom van 50% vast te stellen die de Opdrachtgever vóór ingang van de
					huurperiode aan The DJ Company dient te voldoen.
				</li>
				<li>
					Tenzij Partijen uitdrukkelijk schriftelijk anders zijn overeengekomen, zal The DJ Company het gehuurde op de
					locatie van de Opdrachtgever afleveren en het gehuurde na afloop van de gebruiksperiode bij de Opdrachtgever
					ophalen.
				</li>
				<li>
					De Opdrachtgever dient het gehuurde direct na ontvangst op gebreken en/of beschadigingen te controleren.
					Eventuele gebreken, beschadigingen e.d. dient de Opdrachtgever zo snel mogelijk - maar uiterlijk binnen 24 uur
					na ontvangst van het gehuurde - aan The DJ Company te melden, bij gebreke waarvan het gehuurde geacht wordt
					zonder gebreken en in onbeschadigde staat door de Opdrachtgever te zijn ontvangen.
				</li>
				<li>
					De Opdrachtgever is verplicht het gehuurde gedurende de huurperiode in goede staat te houden en is
					aansprakelijk voor alle tijdens de huurperiode ontstane schade, ook indien deze schade aan het gehuurde door
					de door de Opdrachtgever ingeschakelde derden of bezoekers van de Opdracht wordt veroorzaakt, alsmede voor
					verlies of diefstal. Schade, verlies en/of diefstal dient direct na ontstaan c.q. constatering aan The DJ
					Company te worden gemeld onder opgaaf van alle bijzonderheden.
				</li>
				<li>
					Herstel van schade of defecten aan c.q. vervanging van onderdelen van het gehuurde mag alleen worden
					uitgevoerd door The DJ Company of - na diens uitdrukkelijke toestemming - op aanwijzing van The DJ Company.
					Bij vervanging van defecte onderdelen zal de Opdrachtgever deze onderdelen bewaren en aan The DJ Company
					overhandigen bij terug levering van het gehuurde. Indien de Opdrachtgever deze onderdelen niet bewaart of
					indien de onderdelen niet defect blijken te zijn, zijn de kosten van vervanging van de onderdelen voor
					rekening van de Opdrachtgever.
				</li>
				<li>
					De Opdrachtgever is verplicht zorg te dragen voor een veilige opslag c.q. bewaring van het gehuurde gedurende
					de huurperiode. De Opdrachtgever zal te allen tijde aan The DJ Company of zijn gevolmachtigde toegang verlenen
					tot de locatie waar het gehuurde zich bevinden om de beveiliging en de opslag van het gehuurde te inspecteren.
					Maatregelen die naar het oordeel van The DJ Company nodig zijn om veilige opslag of goede beveiliging te
					garanderen zijn voor rekening van de Opdrachtgever.
				</li>
				<li>
					De Opdrachtgever dient het gehuurde gedurende de looptijd van de Overeenkomst in ieder geval te verzekeren
					voor de gebruikelijk risico’s, zoals schade, verlies en tenietgaan van het gehuurde. Indien het gehuurde
					verloren gaat of onherstelbare schade oploopt, is de Opdrachtgever een door The DJ Company te bepalen
					schadevergoeding verschuldigd, ter grootte van de nieuwwaarde van het gehuurde, vermeerderd met de kosten van
					het inhuren van vervangende Zaken of gemiste huuropbrengsten.
				</li>
				<li>
					Na afloop van de huurperiode dient de Opdrachtgever het gehuurde in de oorspronkelijke emballage en in de
					staat waarin het zich bij ontvangst bevond, bij The DJ Company af te leveren c.q. aan The DJ Company ter
					beschikking te stellen.
				</li>
				<li>
					The DJ Company zal het gehuurde na retournering direct inspecteren. De Opdrachtgever heeft het recht om bij
					deze inspectie aanwezig te zijn. Eventuele kosten in verband met tenietgaan of vermissing (van onderdelen) van
					het gehuurde evenals noodzakelijke kosten voor reiniging en reparatie, komen voor rekening van de
					Opdrachtgever.
				</li>
				<li>
					Indien Partijen zijn overeengekomen dat The DJ Company het gehuurde bij de Opdrachtgever aflevert, worden
					vertragingen ontstaan tijdens het laden, het transport en het lossen van het gehuurde, welke te wijten zijn
					aan omstandigheden die in redelijkheid voor rekening en risico van de Opdrachtgever komen, evenals de tijd die
					gemoeid is met reparaties die het gevolg zijn van nalatigheid van de Opdrachtgever, eveneens onder de
					huurperiode begrepen.
				</li>
				<li>
					Voor iedere vertraging in de teruggave van het gehuurde na het verstrijken van de in de Overeenkomst genoemde
					periode, is de Opdrachtgever een in redelijkheid door The DJ Company te bepalen vergoeding verschuldigd,
					waaronder begrepen de kosten van het inhuren van vervangende Zaken of gemiste huuropbrengsten, onverminderd
					het recht van The DJ Company op volledige schadevergoeding.
				</li>
				<li>
					Indien de Opdrachtgever het gehuurde - om welke reden dan ook - niet retourneert aan The DJ Company, is de
					Opdrachtgever verplicht alle hierdoor geleden schade van The DJ Company te vergoeden, waaronder de nieuwwaarde
					van het gehuurde, de kosten van het inhuren van vervangende Zaken en de gederfde winst.
				</li>
				<li>
					Het gehuurde blijft altijd eigendom van The DJ Company. Het is de Opdrachtgever niet toegestaan het gehuurde
					onder te verhuren, aan derden in gebruik te geven, derden rechten te verstrekken op het gehuurde, onderdelen
					te vervangen of overige wijzigingen op of aan het gehuurde aan te brengen anders dan na voorafgaande
					schriftelijke toestemming van The DJ Company. De Opdrachtgever dient te allen tijde te voorkomen dat bij
					derden de verwachting of indruk wordt gewekt, dat hij tot verdere beschikking over het gehuurde bevoegd is.
				</li>
				<li>
					Indien er sprake is van beslaglegging op het gehuurde - daaronder begrepen fiscaal bodembeslag - of indien
					gegronde vrees bestaat dat dit zal geschieden, dient de Opdrachtgever dit onverwijld aan The DJ Company te
					melden en de beslaglegger direct te informeren dat het gehuurde eigendom is van The DJ Company.
				</li>
				<li>
					The DJ Company is gerechtigd alle voor rekening van de Opdrachtgever komende kosten en schade, voor zover dit
					mogelijk is, te verrekenen met de van de Opdrachtgever ontvangen waarborgsom.
				</li>
			</ol>
			<h2>Artikel 12. Toepasselijk recht</h2>
			<ol>
				<li>
					Alle geschillen welke voortvloeien uit de Overeenkomst tussen The DJ Company en Opdrachtgever zullen worden
					voorgelegd aan een mediator. Indien de bemiddelingspoging van de mediator niet leidt tot een voor beide
					Partijen aanvaardbare oplossing dan zal de kwestie voorgelegd worden aan de bevoegde rechter in het
					arrondissement alwaar The DJ Company op dat moment is gevestigd.
				</li>
				<li>Op de rechtsverhouding tussen partijen is Nederlands recht van toepassing.</li>
				<li>
					The DJ Company kent een interne klachtenregeling, welke gestart kan worden via info@thedjcompany.be of +32
					228838640
				</li>
				<li>
					Een geschillenregeling kan bij de Consumentenombudsdienst (https://consumentenombudsdienst.be/nl) en het
					Online Dispute Resolution platform bewerkstelligd worden
					(https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=EN) bestaat
				</li>
				<li>
					“Mochten we er samen toch niet uitkomen, dan kan je als consument terecht bij Safeshops. SafeShops.be zal
					bemiddelen tussen de consument en de verkoper indien de klacht op het eerste zicht gegrond is. Je kan hen
					bereiken via het klachtenformulier op https://www.safeshops.be/nl/consumers-complaints/ of schriftelijk:
					Kapelsesteenweg 195/1, 2180 Ekeren, info@Safeshops.be”.
				</li>
			</ol>
			<p>
				Wij hopen dat alle voorwaarden voor u duidelijk zijn. Mocht dit niet het geval zijn, neem dan vooral contact met
				ons op. Wij staan u graag te woord!
			</p>
			<p>
				Met muzikale groeten,<br />
				The DJ Company Team
			</p>
			<p>
				The DJ Company B.V.&nbsp;<br />
				Lange Lozanastraat 142<br />
				2018 Antwerpen<br />
				KVK: 73421413<br />
				IBAN: NL56 RABO 0136274153<br />
				Btw-id: NL859531570B01<br />
				<a href="mailto:info@thedjcompany.be">info@thedjcompany.be</a><br />
				+32 3 3002797
			</p>
		</span>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "TermsAndConditions",
	computed: {
		...mapGetters({
			isDutch: "isDutch",
			isBelgian: "isBelgian",
		}),
	},
};
</script>
