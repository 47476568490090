<template>
	<div class="d-block pb-4">
		<div>
			<nav class="mdc-navbar navbar navbar-expand-lg navbar-light m-0 p-0">
				<div @click="showNav = !showNav" :class="{'active-toggler': showNav}" class="navbar-toggler rounded border-0">
					<div v-if="!showNav" class="navbar-toggle-label">
						<div class="p-0 pr-sm-2 p-md-0">Menu</div>
					</div>
					<div v-if="showNav" class="navbar-toggle-label">
						<div class="p-0 pr-sm-2 p-md-0">Sluit</div>
					</div>
					<div class="nav-toggler-icon ml-2" :class="{open: showNav}">
						<span />
						<span />
						<span />
					</div>
				</div>
				<div :class="{in: showNav}" class="collapse navbar-collapse" style="min-height: 85px">
					<affix
						relative-element-selector="#content"
						:offset="{top: 30, bottom: 0}"
						class="tabs-affix w-100"
						:class="navBorderColorClass"
						v-if="!isLoading"
					>
						<ul class="main-navbar-nav navbar-nav w-100 nav-fill max-width">
							<li class="main-nav-item nav-item m-0" @click="showNav = false">
								<router-link class="nav-link py-2 item-color-blue" :to="{name: 'Home'}">
									<span class="nav-item-icon">
										<font-awesome-icon :icon="['fal', 'home']" />
									</span>
									<span class="nav-item-label"><span class="d-line-block d-lg-none">Feest&nbsp;</span>Overzicht</span>
								</router-link>
							</li>
							<li v-if="!isBookingByVenue" class="main-nav-item nav-item has-sub-nav m-0" @click="showNav = false">
								<router-link
									:class="isChildActive('/dj-huren')"
									class="nav-link py-2 item-color-green"
									:to="{name: 'Prices'}"
								>
									<span class="nav-item-icon">
										<font-awesome-icon :icon="['fal', statusIcon]" />
										<span
											v-if="notification"
											class="nav-item-notification d-none d-lg-flex"
											v-text="notification"
										></span>
									</span>
									<span class="nav-item-label">DJ Huren</span>
								</router-link>
								<ul class="d-lg-none sub-navbar-nav navbar-nav w-100 nav-fill">
									<li class="sub-nav-item nav-item m-0" @click="showNav = false">
										<router-link :to="{name: 'Prices'}" class="sub-nav-link nav-link py-2 w-100">
											<span class="pl-4">&boxur;</span>
											<span class="nav-item-icon">
												<font-awesome-icon :icon="['fal', 'calculator']" class="text-primary" />
											</span>
											<span class="nav-item-label">Prijzen berekenen</span>
										</router-link>
									</li>
									<li class="sub-nav-item nav-item m-0" @click="showNav = false">
										<router-link class="sub-nav-link nav-link py-2" :to="{name: 'Quotes'}">
											<span class="pl-4">&boxur;</span>
											<span class="nav-item-icon">
												<font-awesome-icon :icon="['fal', 'calendar-day']" class="text-danger" />
												<span
													v-if="quotes && !isOrderInProgress"
													class="nav-item-notification d-flex"
													v-text="quotes.length"
												></span>
											</span>
											<span class="nav-item-label">Mijn offertes</span>
										</router-link>
									</li>
									<li class="sub-nav-item nav-item m-0" @click="showNav = false">
										<router-link :to="{name: 'Booking'}" class="sub-nav-link nav-link py-2">
											<span class="pl-4">&boxur;</span>
											<span class="nav-item-icon">
												<font-awesome-icon :icon="['fal', 'calendar-check']" class="text-success" />
												<span
													v-if="isOrderInProgress && !isPaid"
													class="nav-item-notification d-flex"
													v-text="1"
												></span>
											</span>
											<span class="nav-item-label">Mijn DJ boeking</span>
										</router-link>
									</li>
								</ul>
							</li>
							<li v-if="!isBookingByVenue" class="main-nav-item nav-item m-0" @click="showNav = false">
								<router-link class="nav-link py-2 item-color-aqua" :to="{name: 'PartyLocation'}">
									<span class="nav-item-icon">
										<font-awesome-icon :icon="['fal', 'map-marker-alt']" />
									</span>
									<span class="nav-item-label">Feestlocatie</span>
								</router-link>
							</li>
							<li class="main-nav-item nav-item m-0" @click="showNav = false">
								<router-link class="nav-link py-2 item-color-orange" :to="{name: 'Reviews'}">
									<span class="nav-item-icon border-1-warning">
										<font-awesome-icon class="stars" :icon="['fas', 'stars']" />
									</span>
									<span class="nav-item-label">Klantervaringen</span>
								</router-link>
							</li>
							<li class="main-nav-item nav-item m-0" @click="showNav = false">
								<router-link
									:class="isChildActive('/feestwensen')"
									:to="{name: 'PartyWishes'}"
									class="nav-link py-2 item-color-purple"
								>
									<span class="nav-item-icon">
										<font-awesome-icon :icon="['fal', 'music']" />
									</span>
									<span class="nav-item-label">Feestwensen</span>
								</router-link>
							</li>
							<li
								v-if="isDutch && !isBookingByVenue"
								@click="showNav = false"
								class="main-nav-item nav-item m-0 border-right-0"
							>
								<router-link class="nav-link py-2 item-color-red" :to="{name: 'Discounts'}">
									<span class="nav-item-icon">
										<font-awesome-icon :icon="['fal', 'percent']" />
									</span>
									<span class="nav-item-label"><span class="d-line-block d-lg-none">Leuke&nbsp;</span>Kortingen</span>
								</router-link>
							</li>
							<li class="main-nav-item nav-item m-0 none-link-nav-item">
								<div class="w-100 d-flex justify-content-between align-items-center">
									<span class="d-block text-left ml-3"
										><strong
											><span class="text-success" style="font-size: 15px">Gratis annuleren</span><br /><small
												class="text-primary"
												>tot 14 dagen voor het feest</small
											></strong
										> </span
									><img
										alt="100% gratis"
										style="max-width: 76px"
										class="img-responsive"
										src="~@/assets/images/illustration-100gratis-2.png"
									/>
								</div>
							</li>
						</ul>
					</affix>
				</div>
			</nav>
			<div v-if="mobileContentTitle" :class="mobileVerticalSubTabsClasses" class="d-lg-none bg-primary">
				<div class="border-bottom main-title">
					<span class="text-white" v-text="mobileContentTitle" />
				</div>
			</div>
			<div v-if="desktopContentTitle" class="d-none d-lg-block text-primary h2 container text-weight-bold mt-4">
				<span v-text="desktopContentTitle" />
			</div>
			<div :class="[sideBarPadding, mobileVerticalSubTabsClasses]" class="mx-auto max-width">
				<div class="row no-gutters">
					<div :class="columnForSidebar" class="order-md-2">
						<SubNav v-if="hasSubnav" class="pt-md-3 pt-lg-4" />
						<div class="px-3 px-md-0 pt-2 pt-md-5">
							<BackTo v-if="hasBackButton" :to="backButtonTo" :label="backButtonLabel" />

							<transition name="fade">
								<slot />
							</transition>
						</div>
					</div>
					<div class="col-md-3 order-md-1 pt-md-3 pt-lg-4 pr-md-3 pr-xl-5" v-if="hasSidebar">
						<Sidebar v-if="sidebarAdditionalContent" />
					</div>
				</div>
			</div>
		</div>
		<div :class="{show: showNav}" class="navbar-haze" @click="showNav = false" />
	</div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SubNav from "@/components/customer/SubNav";
import Sidebar from "@/components/customer/Sidebar";
import BackTo from "@/components/customer/BackTo";
import {Affix} from "vue-affix";
import {mapGetters} from "vuex";

export default {
	name: "tdc-tabs",
	components: {
		FontAwesomeIcon,
		SubNav,
		BackTo,
		Sidebar,
		Affix,
	},
	data() {
		return {
			showNav: false,
			quote: false,
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			error: "getError",
			isLoading: "getLoadingState",
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			isOrderInProgress: "isOrderInProgress",
			quotes: "getQuotes",
			isPaid: "getPaidStatus",
			djClass: "getCurrentDjClass",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
			isBookingByVenue: "isBookingByVenue",
		}),
		djName: function () {
			if (this.$route.query.dj == "AClass") return "A";
			return this.$route.query.dj;
		},
		notification: function () {
			if (this.isOrderInProgress && !this.isPaid) return 1;
			if (this.quotes && this.quotes.length > 0 && !this.isOrderInProgress) return this.quotes.length;
			return false;
		},
		statusName: function () {
			return this.isOrderInProgress ? "Boeking" : "Offerte";
		},
		statusIcon: function () {
			return this.isOrderInProgress ? "calendar-alt" : "clipboard-list-check";
		},
		hasSubnav: function () {
			return this.subNav ? true : false;
		},
		hasSidebar: function () {
			return this.sideBar ? true : false;
		},
		hasBackButton: function () {
			return this.$route.meta.backButton ? true : false;
		},
		columnForSidebar: function () {
			return this.sideBar ? "col-md-9" : "col-12 col-md-10 col-lg-9 mx-md-auto";
		},
		subNav: function () {
			return this.$route.meta.subNav;
		},
		sideBar: function () {
			return this.$route.meta.sideBar;
		},
		sideBarPadding: function () {
			return this.hasSidebar ? "mr-md-3 mr-lg-3 px-xl-3 mx-xl-auto" : "mx-xl-auto";
		},
		sidebarAdditionalContent: function () {
			return this.$route.meta.sidebarAdditionalContent ? this.$route.meta.sidebarAdditionalContent : false;
		},
		mobileContentTitle: function () {
			if (this.quote && this.$route.name == "Quote")
				return this.$route.meta.mobilePageTitle + " nummer: " + this.quote.Name;
			if (this.booking && this.$route.name == "Booking")
				return this.$route.meta.mobilePageTitle + " nummer: " + this.booking.Name;
			if (this.$route.name == "DirectBookingCart")
				return "Je hebt de " + this.djClass + "-klasse gekozen! Jouw datum en Dj zijn bijna gereserveerd!";
			if (this.$route.name == "DirectBookingConfirmed")
				return "Je " + this.djClass + "-klasse DJ is bevestigd! Betaal de waarborgsom om te boeken.";
			if (this.$route.name == "dj") return this.djName + "-klasse DJ";
			return this.$route.meta.mobilePageTitle;
		},
		mobileVerticalSubTabs: function () {
			return this.$route.meta?.subNav?.mobileVertical ? this.$route.meta.subNav.mobileVertical : false;
		},
		mobileVerticalSubTabsClasses: function () {
			return this.mobileVerticalSubTabs ? "mobile-vertical-tabs-spacing" : "no-mobile-vertical-tabs";
		},
		desktopContentTitle: function () {
			return this.$route.meta.desktopPageTitle;
		},
		backButtonTo: function () {
			return this.$route.meta.backButton.backTo ? this.$route.meta.backButton.backTo : "Home";
		},
		backButtonLabel: function () {
			return this.$route.meta.backButton.backLabel ? this.$route.meta.backButton.backLabel : "terug naar overzicht";
		},
		navBorderColorClass: function () {
			return this.$route.meta.sectionColor ? "nav-color-" + this.$route.meta.sectionColor : "nav-color-blue";
		},
	},
	methods: {
		isChildActive: function (url) {
			return this.$route.path.includes(url) ? "router-link-exact-active" : "";
		},
	},
	mounted() {
		this.quote = this.quotes.find((quote) => quote.Id == this.$route.params.id);
	},
};
</script>

<style lang="scss" scoped>
.tabs-affix-placeholder {
	&.affix {
		@include media-breakpoint-up(lg) {
			position: relative !important;
			height: 110px !important;
		}
	}
}
.tabs-affix {
	border-bottom: 4px solid transparent;
	@include media-breakpoint-down(md) {
		top: 85px !important;
		position: relative;
	}
	@include media-breakpoint-up(lg) {
		width: 100%;
		z-index: 11;
	}
	&.affix,
	&.affix-bottom {
		@include media-breakpoint-up(lg) {
			width: auto;
			left: 0px;
			right: 0px;
			background-color: #f6f6f6;
		}
		@include media-breakpoint-up(xl) {
			width: calc(100% - 30px);
			left: auto;
			right: auto;
		}
		.nav-link {
			@include media-breakpoint-up(lg) {
				padding-top: 5px !important;
				padding-bottom: 5px !important;
			}
		}
		.nav-item-icon {
			@include media-breakpoint-up(lg) {
				margin-bottom: 0px !important;
				height: 32px !important;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		&.nav-color-blue {
			border-bottom-color: $tdc-blue;
		}
		&.nav-color-green {
			border-bottom-color: $tdc-green;
		}
		&.nav-color-aqua {
			border-bottom-color: $tdc-aqua;
		}
		&.nav-color-orange {
			border-bottom-color: $tdc-orange;
		}
		&.nav-color-purple {
			border-bottom-color: $tdc-purple;
		}
		&.nav-color-red {
			border-bottom-color: $tdc-red;
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.35s;
}

.fade-enter-active {
	transition-delay: 0.35s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
.navbar-haze {
	@include transition(all 0.5s ease-in-out);
	background: none;
}
.navbar-haze.show {
	@include media-breakpoint-down(md) {
		position: fixed;
		height: 100%;
		width: 100%;
		background: rgba(247, 247, 247, 0.7);
		z-index: 11;
		top: 0;
		left: 0;
	}
}
.navbar-toggle-label {
	position: absolute;
	color: $tdc-blue;
	top: 11px;
	font-size: 14px;
	left: 47px;
}
#nav-location-contact {
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}
.main-title {
	@extend .p-2;
	padding-left: 10px !important;
	font-size: 1.1rem;
	@include media-breakpoint-up(md) {
		font-size: 1.25rem;
	}
}
.mdc-navbar {
	@include media-breakpoint-down(md) {
		position: fixed !important;
		top: 40px;
		z-index: 12;
		left: 0px !important;
	}
	.navbar-toggler {
		padding: 0;
		background-color: #fff;
		&.active-toggler {
			background-color: #f6f6f6;
		}
		@include media-breakpoint-down(md) {
			width: 95px;
			height: 35px;
			position: relative;
			z-index: 99;
			left: 10px;
			border-radius: 40px !important;
		}
		.nav-toggler-icon {
			@include transition(0.5s ease-in-out);
			top: -3px;
			width: 90px;
			height: 50px;
			position: relative;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			cursor: pointer;
			span {
				@include transition(all 0.5s ease-in-out);
				display: block;
				position: absolute;
				height: 5px;
				width: 30px;
				background: $tdc-blue;
				border-radius: 50px;
				opacity: 1;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			span:nth-child(1) {
				top: 10px;
			}
			span:nth-child(2) {
				top: 18px;
			}
			span:nth-child(3) {
				top: 26px;
			}
			&.open {
				span:nth-child(1) {
					top: 18px;
					-webkit-transform: rotate(135deg);
					-moz-transform: rotate(135deg);
					-o-transform: rotate(135deg);
					transform: rotate(135deg);
				}
				span:nth-child(2) {
					opacity: 0;
					left: -60px;
				}
				span:nth-child(3) {
					top: 18px;
					-webkit-transform: rotate(-135deg);
					-moz-transform: rotate(-135deg);
					-o-transform: rotate(-135deg);
					transform: rotate(-135deg);
				}
			}
		}
	}
	.navbar-collapse {
		@include transition(all 0.3s ease-in-out);
		@include media-breakpoint-down(md) {
			overflow-x: scroll;
			transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
			display: block !important;
			position: fixed;
			opacity: 1;
			width: 100%;
			right: 100%;
			top: 0px;
			opacity: 1;
			height: 100%;
			background-color: #fff;
			width: 450px;
			> ul {
				top: 84px;
				position: relative;
			}
			&.in {
				transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
				-webkit-box-shadow: 0 33px 52px -24px rgb(0 0 0 / 53%);
				-moz-box-shadow: 0 33px 52px -24px rgba(0, 0, 0, 0.53);
				box-shadow: 0 33px 52px -24px rgb(0 0 0 / 53%);
			}
		}
		@include media-breakpoint-down(sm) {
			width: 320px;
		}
	}
	.main-navbar-nav {
		@include media-breakpoint-up(lg) {
			border-right: 1px solid #f6f6f6;
		}
	}
	.sub-navbar-nav {
		border-left: 2px solid darken(#efefef, 10%);
		position: relative;
		background: rgba(#e5e5e5, 0.3);
		&:after {
			z-index: 0;
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			width: 100%;
			-webkit-box-shadow: inset 0 0 26px -1px rgb(0 0 0 / 20%);
			-moz-box-shadow: inset 0 0 26px -1px rgba(0, 0, 0, 0.2);
			box-shadow: inset 0 0 26px -1px rgb(0 0 0 / 20%);
		}
	}
	.nav-item {
		@include transition(all 0.2s ease-in-out);
		text-align: left;
		border-top: 1px solid darken(#efefef, 10%);
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(lg) {
			border-left: 2px solid #f6f6f6;
			border-top: none;
		}
		.nav-item-color-bar {
			@include transition(all 0.5s ease-in-out);
			@include media-breakpoint-up(lg) {
				display: none;
				position: absolute;
				height: 4px;
				width: 5000%;
				bottom: -4px;
				z-index: 0;
				right: 0;
			}
		}
		.nav-item-icon {
			@include transition(all 0.2s ease-in-out);
			@extend .align-items-center;
			@extend .d-flex;
			position: relative;
			width: 49px;
			height: 49px;
			border-radius: 50%;
			color: #fff;
			margin-right: 10px;
			margin-left: 15px;
			@include media-breakpoint-up(lg) {
				margin: 0 auto -4px auto;
				display: block;
			}
			svg {
				color: #838b90;
				margin: 0 auto;
				height: 60%;
				width: 60%;
				margin: 0 auto;
			}
			.nav-item-notification {
				@extend .align-items-center;
				@extend .justify-content-center;
				@extend .p-0;
				@extend .m-0;
				@extend .bg-danger;
				@extend .font-weight-bold;
				position: absolute;
				top: 3px;
				right: 0px;
				font-size: 12px;
				line-height: 19px;
				height: 19px;
				width: 19px;
				border-radius: 50% !important;
			}
		}
		.nav-item-label {
			@extend .d-flex;
			@include media-breakpoint-only(lg) {
				font-size: 13px;
			}
		}
	}
	.sub-nav-item {
		z-index: 1;
		border-top: none;
		.sub-nav-link {
			.nav-item-icon {
				width: 39px;
				height: 39px;
			}
		}
	}
	.none-link-nav-item {
		@include media-breakpoint-up(lg) {
			display: none !important;
			border-left: none !important;
		}
	}
	.nav-link {
		@extend .w-100;
		@extend .d-flex;
		@extend .flex-row;
		@extend .flex-lg-column;
		@extend .align-items-center;
		@include transition(all 0.2s ease-in-out);
		text-decoration: none;
		cursor: pointer;
		background: #f6f6f6;
		font-size: 14px;
		font-weight: 800;
		color: #838b90;
		border-right: none !important;
		&.item-color-blue {
			@include media-breakpoint-down(md) {
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-blue;
				}
			}
		}
		&.item-color-green {
			@include media-breakpoint-down(md) {
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-green;
				}
			}
		}
		&.item-color-aqua {
			@include media-breakpoint-down(md) {
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-aqua;
				}
			}
		}
		&.item-color-orange {
			@include media-breakpoint-down(md) {
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-orange;
				}
			}
		}
		&.item-color-purple {
			@include media-breakpoint-down(md) {
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-purple;
				}
			}
		}
		&.item-color-red {
			@include media-breakpoint-down(md) {
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-red;
				}
			}
		}

		@include media-breakpoint-only(lg) {
			font-size: 15px;
		}
		@include media-breakpoint-up(lg) {
			font-size: 14px;
			border-top: 4px solid #f6f6f6;
			background: rgb(246, 246, 246);
			background: linear-gradient(177deg, rgba(246, 246, 246, 1) 0%, rgba(235, 235, 235, 1) 100%);
		}
		&:hover {
			border-top-color: #fff;
			background: #fff;
			@include media-breakpoint-up(lg) {
				background: rgb(246, 246, 246);
				background: linear-gradient(177deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%);
			}
		}
		svg .fa-primary,
		svg .fa-secondary {
			@include transition(all 0.3s ease-in-out);
		}
		&:hover,
		&.router-link-exact-active {
			border-top-color: auto !important;
			svg {
				&.fa-window-alt {
					--fa-primary-color: #f8f8f8;
					--fa-secondary-color: #009fee;
					--fa-secondary-opacity: 1;
				}
				&.fa-music {
					--fa-primary-color: #a6ce39;
					--fa-secondary-color: #a6ce39;
					--fa-primary-opacity: 1;
					--fa-secondary-opacity: 1;
				}
				&.fa-calendar-check,
				&.fa-clipboard-list-check {
					--fa-primary-color: #a6ce39;
					--fa-secondary-color: #e7ffa4;
					@include media-breakpoint-up(lg) {
						--fa-secondary-color: white;
					}
					--fa-primary-opacity: 1;
					--fa-secondary-opacity: 1;
				}
				&.fa-city {
					--fa-primary-color: #3b5998;
					--fa-secondary-color: #ffc107;
					--fa-primary-opacity: 1;
					--fa-secondary-opacity: 1;
				}
				&.fa-star {
					transform: scale(1.3);
					transform-origin: center;
					color: #ffd556 !important;
				}
				&.fa-heart-circle {
					--fa-primary-color: red;
					--fa-secondary-color: white;
					--fa-primary-opacity: 1;
					--fa-secondary-opacity: 1;
				}
				&.fa-badge-percent {
					--fa-primary-color: #009fee;
					--fa-secondary-color: #c9e7f5;
					@include media-breakpoint-up(lg) {
						--fa-secondary-color: white;
					}
					--fa-primary-opacity: 1;
					--fa-secondary-opacity: 1;
				}
			}
		}
	}
	.router-link-exact-active {
		position: relative;
		background: #fff;
		color: #5e5e5e !important;
		.nav-item-label {
			text-decoration: none;
		}
		@include media-breakpoint-down(md) {
			background: #ececec !important;
		}
		@include media-breakpoint-up(lg) {
			.nav-item-color-bar {
				display: block;
			}
			&.item-color-blue {
				border-top: 4px solid $tdc-blue;
				.nav-item-color-bar {
					background-color: $tdc-blue;
				}
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-blue;
				}
			}
			&.item-color-green {
				border-top: 4px solid $tdc-green;
				.nav-item-color-bar {
					background-color: $tdc-green;
				}
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-green;
				}
			}
			&.item-color-aqua {
				border-top: 4px solid $tdc-aqua;
				.nav-item-color-bar {
					background-color: $tdc-aqua;
				}
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-aqua;
				}
			}
			&.item-color-orange {
				border-top: 4px solid $tdc-orange;
				.nav-item-color-bar {
					background-color: $tdc-orange;
				}
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-orange;
				}
			}
			&.item-color-purple {
				border-top: 4px solid $tdc-purple;
				.nav-item-color-bar {
					background-color: $tdc-purple;
				}
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-purple;
				}
			}
			&.item-color-red {
				border-top: 4px solid $tdc-red;
				.nav-item-color-bar {
					background-color: $tdc-red;
				}
				.nav-item-icon svg,
				.nav-item-label {
					color: $tdc-red;
				}
			}
			border-right: 1px solid #f6f6f6;
			border-bottom: none;
			-webkit-box-shadow: 0 0 52px -24px rgb(0 0 0 / 73%);
			-moz-box-shadow: 0 0 52px -24px rgba(0, 0, 0, 0.73);
			box-shadow: 0 0 52px -24px rgb(0 0 0 / 73%);
			&::after {
				top: 99%;
				left: 49%;
				border: solid transparent;
				content: "";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(255, 255, 255, 0);
				border-top-color: #ffffff;
				border-width: 14px;
				margin-left: -14px;
				z-index: 999;
			}
		}
		&:hover {
			color: #5e5e5e !important;
			background: #fff !important;
		}
	}
}
</style>
