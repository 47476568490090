<template>
	<div>
		<div :class="{show: showMoreMobileVertical}" class="subnavtab-haze" @click="showMoreMobileVertical = false" />
		<b-navbar :class="[mobileVerticalClass, mobileVerticalExpandedClass]" class="p-0" :id="navStylingID">
			<b-nav :class="{'flex-column flex-md-row': mobileVertical}" tabs class="mr-0 pr-0 d-flex align-items-stretch">
				<b-nav-item
					v-for="(item, index) in getItems($route.meta.subNav.name)"
					:key="index"
					:to="item.route"
					:class="[showNavItem(item.exclusiveFor) ? '' : 'd-none']"
					:id="item.label"
					activeClass="active"
				>
					<font-awesome-icon
						v-if="item.icon"
						:icon="[item.iconStyle, item.icon]"
						:class="item.iconColor"
						class="mb-2 nav-item-icon"
					/>
					<span class="nav-item-label"
						>{{ subnavLabel(item.label) }}
						<span
							v-if="item.notification == 'quotes' && !isOrderInProgress && quotes.length > 0"
							v-text="'(' + quotes.length + ')'"
						></span>
						<span v-if="item.notification == 'booking' && isOrderInProgress && !isPaid">(1)</span></span
					>
				</b-nav-item>
				<b-nav-item
					v-if="mobileVertical"
					class="d-md-none border-top"
					@click.prevent="showMoreMobileVertical = !showMoreMobileVertical"
				>
					<font-awesome-icon
						:class="{rotate180: mobileVerticalExpandedClass}"
						:icon="['fal', 'chevron-right']"
						class="mb-2 nav-item-icon text-dark"
					/>
					<span class="nav-item-label text-dark">Sluiten</span>
				</b-nav-item>
			</b-nav>
		</b-navbar>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "SubNav",
	computed: {
		...mapGetters({
			booking: "getBooking",
			isOrderInProgress: "isOrderInProgress",
			quotes: "getQuotes",
			isPaid: "getPaidStatus",
			suppliers: "getSuppliers",
			partyType: "getPartyType",
			isWedding: "isWedding",
			isBirthday: "isBirthday",
		}),
		navStylingID: function () {
			return this.$route.meta.subNav.name;
		},
		mobileVertical: function () {
			return !!this.$route.meta?.subNav?.mobileVertical;
		},
		mobileVerticalClass: function () {
			return this.mobileVertical ? "mobile-vertical" : "";
		},
		mobileVerticalExpandedClass: function () {
			return this.showMoreMobileVertical ? "mobile-vertical-expanded" : "";
		},
		exclusiveNavItem: function () {
			if (this.isWedding) return "weddings";
			else if (this.isBirthday) return "birthdays";
			return "";
		},
	},
	methods: {
		getItems(nav) {
			if (nav !== "DiscountsNav") return this.items[nav];
			var supplierRoutes = this.suppliers;

			supplierRoutes.map((supplier) => {
				supplier.label = supplier.Name;
				supplier.route = {};
				supplier.route.name = "Discounts";
				supplier.route.hash = "#" + supplier.Name.toLowerCase().replaceAll(" ", "_");
			});
			return supplierRoutes;
		},
		subnavLabel: function (label) {
			if (this.isWedding && label == "DJ") return "Jullie DJ";
			if (!this.isWedding && label == "DJ") return "Jouw DJ";
			return label;
		},
		showNavItem: function (menu) {
			if (menu == "") return true;
			else if (menu == "birthdays" && this.isBirthday) return true;
			else if (menu == "weddings" && this.isWedding) return true;
			return false;
		},
	},
	data() {
		return {
			showMoreMobileVertical: false,
			items: {
				BookingNav: [
					{
						label: "Prijzen berekenen",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "calculator",
						exclusiveFor: "",
						route: {name: "Prices"},
					},
					{
						label: "Mijn offertes",
						iconStyle: "fal",
						iconColor: "text-danger",
						icon: "calendar-day",
						exclusiveFor: "",
						route: {name: "Quotes"},
						notification: "quotes",
					},
					{
						label: "Mijn DJ boeking",
						iconStyle: "fal",
						iconColor: "text-success",
						icon: "calendar-check",
						exclusiveFor: "",
						route: {name: "Booking"},
						notification: "booking",
					},
				],
				PartyWishesNav: [
					{
						label: "Overzicht",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "list",
						exclusiveFor: "",
						route: {name: "PartyWishes"},
					},
					{
						label: "Muziek",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "music",
						exclusiveFor: "",
						route: {name: "PartyWishes", hash: "#music"},
					},
					{
						label: "Feestdetails",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "party-horn",
						exclusiveFor: "",
						route: {name: "PartyWishes", hash: "#partydetails"},
					},
					{
						label: "Openingsdans",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "rings-wedding",
						exclusiveFor: "weddings",
						route: {name: "PartyWishes", hash: "#firstdance"},
					},
					{
						label: "DJ",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "headphones-alt",
						exclusiveFor: "",
						route: {name: "PartyWishes", hash: "#dj"},
					},
					{
						label: "Opbouw & Apparatuur",
						iconStyle: "fal",
						iconColor: "text-primary",
						icon: "speakers",
						exclusiveFor: "",
						route: {name: "PartyWishes", hash: "#equipment"},
					},
				],
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.active-styling {
	.nav-item-label {
		text-decoration: none;
		color: black;
	}
	text-decoration: none !important;
	border-bottom-color: #f6f6f6 !important;
	text-decoration: none !important;
	background: rgb(246, 246, 246);
	background: linear-gradient(0deg, rgba(246, 246, 246, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.rotate180 {
	-webkit-transform: rotate(540deg);
	-moz-transform: rotate(540deg);
	-ms-transform: rotate(540deg);
	-o-transform: rotate(540deg);
	transform: rotate(540deg);
}
.nav {
	width: 100%;
	@extend .d-flex;
	@extend .flex-row;
	@extend .flex-nowrap;
	.nav-item {
		.nav-link {
			@extend .py-3;
			@extend .p-0;
			@extend .d-flex;
			@extend .align-items-center;
			@extend .text-center;
			color: $tdc-blue;
			flex-direction: column !important;
			border-radius: 0px !important;
			height: calc(100% + 1px);
			border-color: #e9ecef;
			background: rgb(215, 215, 215);
			background: linear-gradient(0deg, rgba(215, 215, 215, 1) 0%, rgba(243, 243, 243, 1) 43%);
			@include media-breakpoint-up(md) {
				border-radius: 4px 4px 0 0;
			}
			.nav-item-icon {
				height: 24px;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
			.nav-item-label {
				font-size: 0.75rem;
				line-height: 1rem;
				@include media-breakpoint-up(md) {
					font-size: 14px;
					line-height: 1rem;
				}
				@include media-breakpoint-only(lg) {
					font-size: 13px;
				}
			}
		}
	}
	li:first-child {
		.nav-link {
			border-radius: 4px 0 0 0 !important;
		}
	}
	li:last-child {
		.nav-link {
			border-radius: 0 4px 0 0 !important;
		}
	}
}
#PartyWishesNav {
	@include media-breakpoint-down(sm) {
		display: none;
	}

	.nav-item {
		width: 33.33333%;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		.nav-link {
			border-radius: 0 4px 4px 0;
			&.router-link-exact-active {
				@extend .active-styling;
				@include media-breakpoint-down(sm) {
					position: relative;
					-webkit-box-shadow: 0 0px 15px 0px rgb(0 0 0 / 12%);
					-moz-box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.12);
					box-shadow: 0 0px 15px 0px rgb(0 0 0 / 12%);
					width: 115%;
				}
			}
			&.router-link-exact-active,
			&.active {
				@include media-breakpoint-down(sm) {
					border-color: transparent;
				}
			}
			.nav-item-label {
				@include media-breakpoint-down(md) {
					padding: 0 10px;
					font-size: 0.85rem;
				}
				@include media-breakpoint-down(sm) {
					//display: none !important;
					-webkit-transition: all 0.25s ease-in-out;
					-moz-transition: all 0.25s ease-in-out;
					-o-transition: all 0.25s ease-in-out;
					transition: all 0.25s ease-in-out;
					opacity: 0;
					left: -150px;
				}
			}
			.nav-item-icon {
				width: 50px;
				@include media-breakpoint-down(sm) {
					margin: 0 !important;
				}
			}
		}
	}
}
#BookingNav {
	.nav-item {
		width: 33.33333%;
		@include media-breakpoint-up(md) {
			width: auto;
			min-width: 150px;
		}
		@include media-breakpoint-up(md) {
			min-width: 190px;
		}
		.nav-link {
			&.router-link-exact-active,
			&.active {
				@extend .active-styling;
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.mobile-vertical {
		background: #eeeeee;
		position: fixed !important;
		width: 50px;
		z-index: 9;
		left: 0;
		top: 85px;
		height: 100%;
		align-items: flex-start !important;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		.nav-item {
			width: 100% !important;
			.nav-link {
				background: #eeeeee;
				flex-direction: row !important;
				.nav-item-icon {
				}
				.nav-item-label {
					position: absolute !important;
				}
			}
		}
		&.mobile-vertical-expanded {
			width: 270px;
			-webkit-box-shadow: 0 33px 52px -24px rgb(0 0 0 / 53%);
			-moz-box-shadow: 0 33px 52px -24px rgba(0, 0, 0, 0.53);
			box-shadow: 0 33px 52px -24px rgb(0 0 0 / 53%);
			.nav-item {
				.nav-link {
					&.router-link-exact-active,
					&.active {
						width: 100% !important;
					}
					.nav-item-label {
						opacity: 1 !important;
						left: 50px !important;
					}
					.nav-item-icon {
						width: 60px !important;
					}
				}
			}
		}
	}
}
.subnavtab-haze {
	@include transition(all 0.5s ease-in-out);
	background: none;
}
.subnavtab-haze.show {
	@include media-breakpoint-down(md) {
		position: fixed;
		height: 100%;
		width: 100%;
		background: rgba(247, 247, 247, 0.7);
		z-index: 9;
		top: 0;
		left: 0;
	}
}
</style>
