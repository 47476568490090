<template>
	<div>
		<div class="nav-speechbubble d-flex order-2 align-items-center">
			<img
				class="d-inline order-2 align-self-start mt-2 loading"
				src="~@/assets/images/tette-cutout.png"
				data-was-processed="true"
			/>
			<div class="px-3 py-1 my-0 speech-bubble justify-content-between align-items-center d-flex">
				<p style="margin-bottom: 0; font-size: 13px" id="quote" v-html="headerMessage"></p>
			</div>
			<div class="speech-bubble-pointer pointer-on-right"></div>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
	name: "speech-bubble",
	computed: {
		...mapGetters({
			messages: "getMessages",
		}),
		headerMessage: function () {
			return this.messages.length > 0 ? this.messages[0].message__c : "Welkom op Mijn DJ Company!";
		},
	},
};
</script>
<style lang="scss">
.nav-speechbubble {
	@include transition(all 0.25s ease-in-out);
	position: relative;
	height: 100% !important;
	overflow: hidden;
	max-width: 600px;
	@include media-breakpoint-down(md) {
		display: none !important;
	}
	img {
		@include transition(all 0.25s ease-in-out);
		height: 115px;
	}
	.speech-bubble-pointer {
		background-image: url(~@/assets/images/speech-pointer-l.png);
		width: 30px;
		height: 30px;
		background-position: right;
		background-size: cover;
		margin-right: -35px;
	}
	.speech-bubble {
		max-height: 90%;
		background: #fff;
		border-radius: 4px;
		.info-icon {
			color: #dedede;
			font-size: 45px;
			@include transition(all 0.25s ease-in-out);
		}
	}
}
</style>
