<template>
	<div class="d-flex justify-content-end">
		<div class="btn-group mr-1 mr-md-3">
			<router-link
				:to="{name: 'ContactDetails'}"
				class="p-0 btn border-0 d-flex align-items-center rounded-circle"
				style="height: 35px; width: 35px; background-color: #fff"
			>
				<div class="secondary-toggler mx-auto">
					<font-awesome-icon class="text-primary" :icon="['fas', 'user']" />
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
	name: "secondary-nav",
	components: {
		FontAwesomeIcon,
	},
};
</script>
