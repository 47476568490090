<template>
	<div
		v-if="daysLeft >= 0 && booking.Party_Date__c"
		class="align-items-center d-none d-lg-flex justify-content-center nav-calendar"
	>
		<img src="~@/assets/images//mdc-calendar.png" alt="Kalender" data-was-processed="true" />
		<div class="nav-calendar-content d-flex flex-column position-absolute text-center">
			<div class="count-row1" style="font-size: 9px" v-text="daysLeftInitialLabel"></div>
			<div v-text="daysLeftNumberLabel"></div>
			<div
				class="count-row3"
				style="font-size: 9px"
				v-html="daysLeftDayLabel + ' ' + daysLeftAdditionalLabel + '<br />het feest'"
			></div>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
	name: "days-left",
	computed: {
		...mapGetters({
			booking: "getBooking",
			messages: "getMessages",
			isLoading: "getLoadingState",
		}),
		daysLeft: function () {
			return this.$date(this.booking.Party_Date__c).diff(this.$date(), "days");
		},
		daysLeftNumberLabel: function () {
			return this.daysLeft >= 0 ? this.daysLeft : "?";
		},
		daysLeftDayLabel: function () {
			return this.daysLeft == 1 ? "dag" : "dagen";
		},
		daysLeftAdditionalLabel: function () {
			return this.daysLeft >= 0 ? "tot" : "sinds";
		},
		daysLeftInitialLabel: function () {
			return this.daysLeft >= 0 ? "Nog" : "Alweer";
		},
	},
};
</script>
<style lang="scss" scoped>
.nav-calendar {
	img {
		height: 70px;
		@include media-breakpoint-up(lg) {
			height: 72px;
		}
	}
	.nav-calendar-content {
		-ms-transform: rotate(-5deg);
		-webkit-transform: rotate(-5deg);
		transform: rotate(-5deg);
		line-height: 12px;
		#count_days {
			font-weight: 800;
		}
		@include media-breakpoint-up(lg) {
			line-height: 13px;
		}
		.count-row3 {
			line-height: 12px;
		}
	}
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}
</style>
