<template>
	<b-modal
		id="tc"
		ok-only
		size="xl"
		header-bg-variant="light"
		header-text-variant="dark"
		footer-bg-variant="light"
		footer-text-variant="dark"
		scrollable
	>
		<template #modal-header="{close}">
			<div class="d-flex w-100 flex-row justify-content-between">
				<h5 class="m-0 p-0 w-100 flex-grow-1">Algemene voorwaarden</h5>
				<b-button
					size="sm"
					variant="outline-primary"
					@click="close()"
					class="d-flex align-items-center flex-shrink-1"
					style="width: auto"
				>
					Sluiten
				</b-button>
			</div>
		</template>
		<template #modal-footer="{ok}">
			<div class="d-flex w-100 flex-row justify-content-start">
				<b-button
					size="sm"
					variant="outline-primary"
					@click="ok()"
					class="d-flex align-items-center flex-shrink-1"
					style="width: auto"
				>
					Sluiten
				</b-button>
			</div>
		</template>
		<TermsAndConditions />
	</b-modal>
</template>
<script>
import TermsAndConditions from "@/components/customer/TermsAndConditions";

export default {
	name: "TermsAndConditionsModal",

	components: {
		TermsAndConditions,
	},
};
</script>
