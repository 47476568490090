<template>
	<div :id="subnavHashId" style="margin-top: -1px">
		<Header v-if="noBookingErrors" />
		<div v-if="noBookingErrors">
			<div class="body-spacing"></div>
			<div id="content">
				<div class="mdc-booking-content-wrapper">
					<b-overlay :show="isLoading" z-index="9999">
						<Tabs v-if="booking">
							<router-view v-if="'Account__c' in booking"></router-view>
						</Tabs>
						<template #overlay>
							<div class="text-center">
								<font-awesome-icon class="text-primary fa-2x" :icon="['fas', 'music']" />
								<p class="text-primary font-weight-bold">Een muzikaal moment svp...</p>
							</div>
						</template>
					</b-overlay>
				</div>
			</div>
		</div>
		<Footer v-if="noBookingErrors" />
		<b-modal
			:visible="!noBookingErrors"
			id="error-modal"
			hide-header
			hide-footer
			size="md"
			:no-close-on-backdrop="error.status === 404"
			:no-close-on-esc="error.status === 404"
			class="error-modal"
			:centered="true"
		>
			<Alert v-if="error.status === 500" alertType="danger" class="mb-0">
				Oops, er ging iets fout. Excuus voor het ongemak. The DJ Company is op de hoogte gebracht van deze fout.<br />
				Probeer het nogmaals, of neem contact via <a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a>
				<div class="d-flex justify-content-start mt-3">
					<b-button class="mr-1" size="md" variant="light" @click="$store.commit('RESET_ERROR')">
						Sluit melding<font-awesome-icon class="ml-1" :icon="['fas', 'times']" />
					</b-button>
					<b-button class="ml-1" size="md" variant="primary" @click="openTrengo()">
						Start chat <font-awesome-icon class="ml-1" :icon="['fas', 'comments']" />
					</b-button>
				</div>
			</Alert>
			<Alert alertType="danger" v-if="error.status === 404">
				Deze boeking/offerte is niet (meer) beschibaar. Dit kan bijvoorbeeld doordat er een dubbele boeking is
				gemaakt.<br />
				Stuur een <a href="#" @click.prevent="openTrengo()">chatbericht</a> of neem contact via
				<a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a> om de juiste boekinglink te achterhalen.
				<div class="d-flex justify-content-start mt-3">
					<b-button size="md" variant="primary" @click="openTrengo()">
						Start chat <font-awesome-icon class="ml-1" :icon="['fas', 'comments']" />
					</b-button>
				</div>
			</Alert>
		</b-modal>
		<TermsAndConditionsModal />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/components/customer/Header";
import Tabs from "@/components/customer/Tabs";
import Footer from "@/components/customer/Footer";
import Alert from "@/components/customer/Alert";
import TermsAndConditionsModal from "@/components/customer/TermsAndConditionsModal";

export default {
	name: "customerLayout",
	components: {
		Header,
		Tabs,
		Footer,
		Alert,
		TermsAndConditionsModal,
	},
	computed: {
		...mapGetters({
			error: "getError",
			booking: "getBooking",
			isLoading: "getLoadingState",
			isBelgian: "isBelgian",
			infoEmailAddress: "getInfoEmailAddress",
			isBookingByVenue: "isBookingByVenue",
		}),
		subnavHashId: function () {
			if (this.$route.name) {
				var routeName = this.$route.name;
				return routeName.toLowerCase();
			} else {
				return "undefined";
			}
		},
		noBookingErrors: function () {
			return this.error && this.error.status > 0 ? false : true;
		},
	},
	data: function () {
		return {
			calcPricesTimeout: null,
			prevValues: {
				partyDate: null,
				startTime: null,
				endTime: null,
				nGuests: null,
			},
			form: {
				partyDate: "",
				startTime: "20:00",
				endTime: "23:00",
				nGuests: 75,
			},
		};
	},
	methods: {
		openTrengo() {
			window.Trengo.Api.Widget.open("chat");
		},
		calcPricesDedup() {
			console.log("calcPricesDedup");
			clearTimeout(this.calcPricesTimeout);
			this.calcPricesTimeout = setTimeout(() => {
				this.calcPrices();
			}, 300);
		},
		calcPrices() {
			console.log("calcPrices");
			if (this.fixedPrices) {
				alert(
					"Sorry de prijzen zijn vastgezet ivm speciale omstandigheden van deze offerte. U kunt de prijzen niet herbereken."
				);
			} else {
				console.log("dispatch calcPrices");
				this.$store.dispatch("postPrices", {
					action: "calcPrices",
					data: this.form,
				});
				if (
					!this.isOrderInProgress &&
					// ignore if not all fields are filled
					this.form.partyDate &&
					this.form.startTime &&
					this.form.endTime &&
					this.form.nGuests
				) {
					console.log("dispatch setPriceFields");
					this.$store.dispatch("postBooking", {
						action: "setPriceFields",
						data: this.form,
						setBooking: true,
					});
				}
			}
		},
	},
	mounted() {
		if (this.noBookingErrors) this.$store.dispatch("getSuppliers");
		this.$root.$on("setTermsAccepted", (val) => {
			console.log("setTermsAccepted", val);
		});
		this.$root.$on("setDate", (partyDate) => {
			console.log("setDate", partyDate);
			if (this.form.partyDate !== partyDate) {
				this.form.partyDate = partyDate;
				this.calcPricesDedup();
			}
		});
		this.$root.$on("setStartTime", (startTime) => {
			console.log("setStartTime", startTime);
			if (this.form.startTime !== startTime) {
				this.form.startTime = startTime;
				this.calcPricesDedup();
			}
		});
		this.$root.$on("setEndTime", (endTime) => {
			console.log("setEndTime", endTime);
			if (this.form.endTime !== endTime) {
				this.form.endTime = endTime;
				this.calcPricesDedup();
			}
		});
		this.$root.$on("setNGuests", (nGuests) => {
			console.log("setNGuests", nGuests);
			if (this.form.nGuests !== nGuests) {
				this.form.nGuests = nGuests;
				this.calcPricesDedup();
			}
		});
	},
};
</script>

<style lang="scss">
.bg-blue {
	background-color: $tdc-blue;
}

#error-modal {
	.modal-content {
		border: none !important;
		background: none !important;
	}

	.modal-body {
		padding: 0 !important;
	}
}
</style>
