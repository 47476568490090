<template>
	<div>
		<div
			class="topbar-bericht usps-bericht d-flex align-items-center flex-row bg-white w-100 position-fixed text-center"
		>
			<span
				class="d-flex justify-content-center justify-content-md-start justify-content-lg-center align-items-center flex-grow-1"
			>
				<span class="align-items-center flex-row d-flex mx-2 mx-lg-3">
					<font-awesome-icon name="1" class="text-success mr-1" :icon="['fas', 'check-circle']" /> Niet goed,
					<strong class="text-primary">&nbsp;geld&nbsp;terug</strong>
				</span>
				<span class="align-items-center flex-row mx-2 mx-lg-3 d-none d-md-flex">
					<font-awesome-icon name="2" class="text-success mr-1" :icon="['fas', 'check-circle']" />
					<strong class="text-primary">Gratis&nbsp;annuleren&nbsp;</strong>tot 14 dagen voor feest
				</span>
				<span class="align-items-center flex-row mx-2 mx-lg-3 d-none d-md-flex">
					<font-awesome-icon name="3" class="text-success mr-1" :icon="['fas', 'check-circle']" />
					<strong class="text-primary">150,-&nbsp;korting&nbsp;</strong> en geen reiskosten
				</span>
			</span>
			<div class="d-none d-lg-block d-xl-none" style="width: 200px"></div>
			<div class="d-none d-lg-block position-absolute" style="font-size: 14px; right: 150px">
				<router-link
					v-if="quotes.length && !isOrderInProgress"
					:to="{name: 'Quotes'}"
					class="d-flex align-items-center badge badge-pill badge-light border border-danger"
				>
					<font-awesome-icon class="mr-2 text-danger" :icon="['fas', 'heart']" />
					<span style="font-size: 13px">Mijn Offertes</span>
					<font-awesome-icon class="ml-1" :icon="['far', 'chevron-right']" />
				</router-link>
				<router-link
					v-if="isOrderInProgress"
					:to="{name: 'Booking'}"
					class="d-flex align-items-center badge badge-pill badge-light border border-success"
				>
					<font-awesome-icon class="mr-2 text-success" :icon="['fas', 'calendar-check']" />
					<span style="font-size: 13px">Mijn DJ Boeking</span>
					<font-awesome-icon class="ml-1" :icon="['far', 'chevron-right']" />
				</router-link>
				<router-link
					v-if="quotes.length == 0 && !isOrderInProgress"
					:to="{name: 'Prices'}"
					class="d-flex align-items-center badge badge-pill badge-light border border-primary"
				>
					<font-awesome-icon class="mr-2 text-primary" :icon="['fas', 'euro-sign']" />
					<span style="font-size: 13px">Bekijk Prijzen</span>
					<font-awesome-icon class="ml-1" :icon="['far', 'chevron-right']" />
				</router-link>
			</div>
			<span class="position-absolute d-none d-md-flex flex-row h-100 align-items-center" style="right: 15px">
				<img class="ml-1" style="max-height: 22px" src="~@/assets/images/seenfrom-10jaar.png" />
				<img v-if="isDutch" class="ml-1" style="max-height: 22px" src="~@/assets/images/seenfrom-tpw.png" />
				<img v-if="isDutch" class="ml-1" style="max-height: 22px" src="~@/assets/images/seenfrom-rtl4.png" />
				<img
					v-if="isBelgian"
					src="https://dashboard.trustprofile.com/banners/4000078/4017273/1652997600.svg"
					alt="Webwinkel Keurmerk en klantbeoordelingen"
					style="max-height: 22px"
					class="ml-1"
				/>
			</span>
		</div>

		<nav v-if="!isLoading" class="navbar navbar-default p-0" role="navigation">
			<div
				class="navbar-row flex-nowrap container-fluid d-flex justify-content-between align-items-center"
				style="padding-left: 5px; padding-right: 5px"
			>
				<div class="d-flex d-lg-none w-33">&nbsp;</div>
				<div class="d-flex position-relative">
					<router-link class="navbar-brand mx-auto mx-lg-0 ml-lg-3 w-33" to="/">
						<img
							id="thedjcompany"
							src="~@/assets/images/logo-mijndjcompany.png"
							alt="The DJ Company"
							class="loading"
							data-was-processed="true"
						/>
						<img class="seasonal position-absolute d-none" src="~@/assets/images/season-easter-eggs.png" />
					</router-link>
				</div>
				<div
					class="nav-contact ml-4 order-0 flex-grow-1 my-2 d-none d-lg-flex align-items-center"
					style="z-index: 11; position: relative"
				>
					<span class="nav-booking-contact mr-0 mr-md-3">
						<strong v-if="'Account__r' in booking">Welkom <span v-text="booking.Account__r.FirstName"></span></strong
						><br />
						<small>Feest: {{ booking.Name }}</small>
					</span>
				</div>

				<DaysLeftCalendar
					:class="'d-none d-lg-flex ml-2 mr-4 order-0 flex-grow-1 flex-md-grow-0 flex-lg-grow-1 my-2'"
				></DaysLeftCalendar>
				<HeaderSpeechBubble :class="'mr-3 d-none d-lg-flex h-100'"></HeaderSpeechBubble>
				<div class="d-flex position-relative w-33 align-items-center justify-content-end">
					<div class="d-flex d-lg-none btn-group justify-content-center text-center mr-1">
						<router-link
							v-if="quotes.length && !isOrderInProgress"
							:to="{name: 'Quotes'}"
							class="rounded-circle btn btn-light border-0 border-danger text-center align-items-center d-flex justify-content-center"
							style="width: 35px; height: 35px; background-color: #fff"
						>
							<font-awesome-icon class="text-danger" :icon="['fas', 'heart']" />
						</router-link>
						<router-link
							v-if="isOrderInProgress"
							:to="{name: 'Booking'}"
							class="rounded-circle btn text-center btn-light border-0 border-success align-items-center d-flex justify-content-center"
							style="width: 35px; height: 35px; background-color: #fff"
						>
							<font-awesome-icon class="text-success" :icon="['fas', 'calendar-check']" />
						</router-link>
						<router-link
							v-if="quotes.length == 0 && !isOrderInProgress"
							:to="{name: 'Prices'}"
							class="rounded-circle btn border-0 border-primary text-center d-flex align-items-center justify-content-center"
							style="width: 35px; height: 35px; background-color: #fff"
						>
							<font-awesome-icon class="text-primary" :icon="['fas', 'euro-sign']" />
						</router-link>
					</div>
					<SecondaryNav v-if="!isBookingByVenue"></SecondaryNav>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";
import SecondaryNav from "@/components/customer/SecondaryNav";
import HeaderSpeechBubble from "@/components/customer/HeaderSpeechBubble";
import DaysLeftCalendar from "@/components/customer/DaysLeftCalendar";

export default {
	name: "tabs",
	components: {
		FontAwesomeIcon,
		SecondaryNav,
		HeaderSpeechBubble,
		DaysLeftCalendar,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isLoading: "getLoadingState",
			quotes: "getQuotes",
			isOrderInProgress: "isOrderInProgress",
			isPreQuote: "isPreQuote",
			isDutch: "isDutch",
			isBelgian: "isBelgian",
			isBookingByVenue: "isBookingByVenue",
		}),
	},
};
</script>

<style lang="scss" scoped>
.seasonal {
	height: auto !important;
	top: -2px !important;
	left: -19px !important;
	width: 130px !important;
	z-index: -1 !important;
	position: absolute !important;
	@include media-breakpoint-up(sm) {
		top: -2px !important;
		left: -28px !important;
		width: 150px !important;
	}
	@include media-breakpoint-up(md) {
		top: -2px !important;
		left: -27px !important;
		width: 150px !important;
	}
	@include media-breakpoint-up(lg) {
		top: -4px !important;
		left: -33px !important;
		width: 190px !important;
	}
}
.topbar-bericht {
	font-size: 13px;
	line-height: 30px;
	height: 30px;
	top: 0;
	@include media-breakpoint-down(md) {
		z-index: 10;
	}
	z-index: 12;
}
.navbar {
	background: none;
	width: 100%;
	top: 30px;
	@include media-breakpoint-down(md) {
		position: fixed !important;
		z-index: 12 !important;
	}
	.input-group-btn {
		vertical-align: top;
	}
	.navbar-row {
		@include transition(all 0.2s ease-in-out);
		z-index: 11;
		background-color: rgba(#c0e3f5, 1);
		height: 55px;
		@include media-breakpoint-up(lg) {
			height: 90px;
		}
		.w-33 {
			@include media-breakpoint-down(md) {
				width: 33%;
			}
		}
		.navbar-brand {
			@include transition(all 0.25s ease-in-out);
			position: relative;
			height: auto;
			padding: 0px;
			font-size: 26px;
			line-height: 20px;
			z-index: 10;
			cursor: pointer;
			width: 125px;
			@include media-breakpoint-down(md) {
				width: 95px;
			}
			@include media-breakpoint-down(xs) {
				width: 75px;
			}
			img {
				@include transition(all 0.25s ease-in-out);
				margin: 0;
				z-index: 1;
				width: 90px;
				height: 40px;
				@include media-breakpoint-up(sm) {
					width: 95px;
					height: 46px;
				}
				@include media-breakpoint-up(lg) {
					width: 125px;
					height: 61px;
				}
			}
		}
		.nav-contact {
			line-height: 17px;
			@include transition(all 0.25s ease-in-out);
			strong {
				display: inline-block;
			}
			@include media-breakpoint-down(md) {
				img {
					height: 45px;
					width: 45px;
				}
			}
			.nav-booking-contact {
				position: relative;
				margin-left: 10px;
				display: inline-block;
				float: left;
				padding-left: 15px;
				@include media-breakpoint-down(lg) {
					margin-left: 0px;
					padding-left: 0px;
				}
				small {
					font-size: 13px;
				}
			}
		}
	}
}
</style>
