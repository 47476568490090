<template>
	<div class="w-100 h-100">
		<div :style="{backgroundImage: 'url(' + image + ')'}" class="rounded w-full location-img location-image"></div>
	</div>
</template>

<script>
export default {
	name: "LocationImage",
	props: {
		image: {
			required: true,
			type: String,
		},
	},
};
</script>

<style lang="scss">
.location-image {
	margin: 0 auto 20px auto;
	height: 100%;
	background-size: cover;
	background-position: center center;
}
</style>
