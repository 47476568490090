<template>
	<div class="sidebar">
		<span v-if="hasLocation">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">
					<span v-if="partyType == 'bruiloftsfeest'">Jullie</span><span v-else>Jouw</span> locatie
				</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'map-marker-alt']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">
				<div class="txt-blue mb-2 font-weight-bold" v-text="locationName"></div>
				<div v-if="showImage" class="w-100 mb-3" style="height: 100px">
					<LocationImage :image="locationBackgroundImage" />
				</div>
				<div v-if="getNumberOfShows > 0">
					The DJ Company scoort een
					<div class="d-inline-block">
						<span class="stars"><StarRating :rating="locationRating" class="d-inline mr-2" /></span
						><strong v-html="locationRating"></strong>
					</div>
					<span v-if="!location.Location_private__c"> op deze locatie</span
					><span v-if="location.Location_private__c"> op thuis- en privélocaties</span>
				</div>
				<div v-else>
					<span v-if="location.Location_private__c"
						>The DJ Company scoort gemiddeld <span class="stars"><StarRating :rating="9" class="d-inline mr-2" /></span
						><strong>9,2</strong> op thuis- en privélocaties</span
					>
				</div>
			</div>
		</span>
		<span v-if="hasGeo">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Feestlocatie</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'map-signs']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">Best beoordeeld en meest geboekt in {{ geo }}</div>
		</span>
		<span v-if="hasLocation && hasParties && !location.Location_private__c">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Leuke feesten</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'balloons']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">
				<span v-if="location.Location_Leads_confirmed_total__c > 3"
					><strong>{{ location.Location_Leads_confirmed_total__c }}</strong> feesten gedraaid bij
					{{ locationName }}</span
				>
				<span v-else>We draaien vaker bij {{ locationName }}</span>
			</div>
		</span>
		<span v-if="location.Location_private__c">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Leuke feesten</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'balloons']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">
				Meest geboekt en als best beoordeeld op thuis- en privélocaties van
				<span v-if="isBelgian" v-text="'Vlaanderen'" /><span v-else v-text="'Nederland'" />.
				<router-link class="ahref" :to="{name: 'Reviews'}">Lees alle klantervaringen</router-link>.
			</div>
		</span>
		<span v-if="hasLocation && locationReviewCount > 0 && !location.Location_private__c">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Klantervaringen</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'comments']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">
				Kortom, veel ervaring!
				<router-link class="ahref" :to="{name: 'PartyLocation'}"
					>Lees klantervaringen van ons bij {{ locationName }}</router-link
				>
			</div>
		</span>
		<span v-if="locationContact != '' && locationContact.Name != 'Hoofd Contact' && !location.Location_private__c">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Contactpersoon</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'user']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">
				We kennen {{ locationContact.Name }}, onze contactpersoon bij {{ locationName }}
			</div>
		</span>

		<span v-if="partyType == 'bruiloftsfeest'">
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Bruiloften</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'rings-wedding']" />
				</div>
			</div>
			<div class="sidebar-content-wrapper">Meer dan 2000 tevreden bruidsparen</div>
		</span>

		<span>
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Meer klantervaringen</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'share-nodes']" />
				</div>
			</div>

			<div class="sidebar-content-wrapper">
				<SocialReviews class="row-cols-2 row-cols-md-1 row-cols-lg-2" />
				<router-link class="ahref" :to="{name: 'Reviews'}">Lees onze klantervaringen</router-link>
			</div>
		</span>

		<span>
			<div class="sidebar-header-wrapper">
				<div class="sidebar-header">Bekijk onze video en proef de sfeer!</div>
				<div class="sidebar-icon">
					<font-awesome-icon :icon="['fal', 'play']" />
				</div>
			</div>

			<div class="position-relative sidebar-content-wrapper">
				<Video v-if="partyType == 'bruiloftsfeest'" videoId="281960527" />
				<Video v-else videoId="307886349" />
			</div>
		</span>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SocialReviews from "@/components/customer/SocialReviews";
import LocationImage from "@/components/customer/LocationImage";
import StarRating from "@/components/customer/StarRating";
import Video from "@/components/customer/Video";

export default {
	components: {
		FontAwesomeIcon,
		SocialReviews,
		LocationImage,
		StarRating,
		Video,
	},
	computed: {
		...mapGetters({
			hasLocation: "hasLocation",
			location: "getLocation",
			locationContact: "getLocationContact",
			hasGeo: "hasGeo",
			geo: "getGeo",
			hasEvaluation: "hasEvaluation",
			isLoading: "getLoadingState",
			partyType: "getPartyType",
			getNumberOfShows: "getNumberOfShows",
			cleanPartyType: "getCleanPartyType",
			isBelgian: "isBelgian",
			locationMedia: "getLocationMedia",
			hasLocationMedia: "hasLocationMedia",
		}),
		currentRouteName() {
			return this.$route.name;
		},
		hasParties() {
			return (
				"Location_Leads_confirmed_total__c" in this.location && this.location.Location_Leads_confirmed_total__c > 0
			);
		},
		showImage: function () {
			return !this.locationIsPrivate && this.hasLocationMedia;
		},
		locationRating: function () {
			return "LocationReviewsForLocScore__c" in this.location ? this.location.LocationReviewsForLocScore__c : 9.2;
		},
		locationReviewCount: function () {
			return "LocationReviewsForLocCount__c" in this.location ? this.location.LocationReviewsForLocCount__c : 0;
		},
		locationName: function () {
			return this.location.Location_private__c ? "Privé locatie" : this.location.Name;
		},
		locationIsPrivate() {
			return this.location.Location_private__c;
		},
		locationBackgroundImage() {
			if (this.hasLocationMedia) {
				if (this.locationIsPrivate) return require("@/assets/images/dj-image.jpg");
				else if (this.hasLocationMedia) return this.locationMedia[0].FileLink__c;
				return require("@/assets/images/dj-image.jpg");
			}
			return require("@/assets/images/grey-gradient.jpg");
		},
	},
	data() {
		return {
			reviewUrl: "https://thedjcompany.nl/klantervaringen/",
		};
	},
};
</script>

<style lang="scss" scoped>
.sidebar {
	padding: 1em;
	@include media-breakpoint-only(md) {
		font-size: 0.85rem;
	}
	@include media-breakpoint-up(md) {
		background-color: #eeeeee;
		padding: 10px;
		height: 100%;
	}
}
.sidebar-header-wrapper {
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-center;
	@extend .mb-2;
	border-bottom: 1px dashed #c5c5c5;
	top: -9px;
	position: relative;
	.sidebar-header {
		@extend .mb-n3;
		@extend .font-weight-bold;
		@extend .text-primary;
		font-size: 0.75rem;
		background-color: #f6f6f6;
		@include media-breakpoint-up(md) {
			background-color: #eeeeee;
		}
		padding-right: 5px;
		position: relative;
		bottom: -3px;
	}
	.sidebar-icon {
		@extend .ml-auto;
		position: relative;
		padding-left: 5px;
		bottom: -9px;
		background-color: #f6f6f6;
		@include media-breakpoint-up(md) {
			background-color: #eeeeee;
		}
		svg {
			font-size: 14px;
			color: $tdc-blue;
		}
	}
}
.sidebar-content-wrapper {
	@extend .mb-3;
}
</style>
